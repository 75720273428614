import React, { useEffect, useState, useRef, useMemo } from "react";
import { Button, Form, Tabs, Tab } from "react-bootstrap";
import { createStructuredSelector } from "reselect";
import {
  searchDataSave,
  getHotelListRequest,
  placeStateDataSave,
  hotelPlaceDataHandle,
  hotelWheatherApiRequest,
} from "./../../redux/hotels/hotel.actions";
import usePlacesAutocomplete from "use-places-autocomplete";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { connect } from "react-redux";
import useOnclickOutside from "react-cool-onclickoutside";
import TextField from "@mui/material/TextField";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import moment from "moment";
import dayjs from "dayjs";
import AutocompleteReact from "./../rectInput-auto-complete/reactInput";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  useNavigate,
  createSearchParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { BsPlusCircle } from "react-icons/bs";
import {
  searchFilterData,
  searchFilterAddOrNot,
  selectFavouriteHotelSearchData,
  selectHotelBidNowPageRouteData,
  selectHotelDetailsPageRouteData,
  selectPlaceDataHandleData,
} from "./../../redux/hotels/hotel.selectors";
import "@reach/combobox/styles.css";
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { isMobile } from "react-device-detect";
const HotelSearchForOthers = ({
  searchDataSave,
  getHotelListRequest,
  searchFilterData,
  languageToShow,
  searchFilterAddOrNot,
  placeStateDataSave,
  selectFavouriteHotelSearchData,
  selectHotelBidNowPageRouteData,
  selectHotelDetailsPageRouteData,
  selectPlaceDataHandleData,
  hotelWheatherApiRequest,
}) => {
  const MySwal = withReactContent(Swal);
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checkoutTime, setCheckoutTime] = useState(false);
  const firstRender = useRef(true);
  let location = useLocation();
  const [searchType, setSearchType] = useState(
    selectHotelBidNowPageRouteData == "BiddingDetails"
      ? selectFavouriteHotelSearchData.searchType
      : searchParams.get("search_type") == null
        ? null
        : searchParams.get("search_type")
  );
  const [getHours, setGetHours] = useState(
    searchParams.get("book_for") == null ? 3 : searchParams.get("book_for")
  );
  const [longaddress, setLongaddress] = useState(
    selectPlaceDataHandleData?.place != null
      ? selectPlaceDataHandleData?.place
      : searchParams.get("city") == null
        ? null
        : searchParams.get("city")
  );
  const [placeSearchError, setPlaceSearchError] = useState("");
  const [searchtypeShowButton, setSearchtypeShowButton] = useState(
    searchParams.get("search_type") == null
      ? null
      : searchParams.get("search_type")
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [guestRomsPopup, setGuestRoomsPopup] = useState(false);
  const [guestRomsPopupsecond, setGuestRoomsPopupsecond] = useState(false);
  const [guestRoomData, setGuestRoomData] = useState({
    rooms: parseInt(
      searchParams.get("rooms") == null ? 1 : searchParams.get("rooms")
    ),
    child: parseInt(
      searchParams.get("children") == null ? 0 : searchParams.get("children")
    ),
    adults: parseInt(
      searchParams.get("adults") == null ? 1 : searchParams.get("adults")
    ),
  });
  const [place, setPlace] = useState(
    searchParams.get("city") == null ? null : searchParams.get("city")
  );
  const [checkIn, setCheckIn] = useState(false);
  if (searchParams.get("check_in_date") != null) {
    var dateHandle1 = searchParams.get("check_in_date").split("/");
  }
  if (searchParams.get("check_out_date") != null) {
    var dateHandle2 = searchParams.get("check_out_date").split("/");
  }
  const [checkInOutValue, setCheckInOutValue] = useState({
    checkInTime:
      searchParams.get("check_in_date") == null
        ? null
        : `${dateHandle1[2]}-${dateHandle1[1]}-${dateHandle1[0]}`,
    checkOutTime:
      searchParams.get("check_out_date") == null
        ? (searchParams.get("search_type") == 'hour' ? moment().add(1, 'days') : null)
        : `${dateHandle2[2]}-${dateHandle2[1]}-${dateHandle2[0]}`,
  });

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({ callbackName: "initMap" });
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  var preference = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var partner = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  var offerPromotion = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const populartoleasting = (citynamedetails) => {
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    window.scroll(0, 0);

    navigate({
      pathname: "listingbidnow",
      search: createSearchParams({
        search_type: "bid",
        city: citynamedetails,
        check_in_date: "" + year + "-" + month + "-" + day + "",
        check_out_date: "" + year + "-" + month + "-" + (day + 1) + "",
        adults: "1",
        children: "0",
        rooms: "1",
      }).toString(),
    });
  };



  /* Date Picker Close After Input */


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  // DatePicker

  const reference = useOnclickOutside(() => {
    // When user clicks outside of the component, call it to clear and reset the suggestions data
    clearSuggestions();
  });
  const guestAndRoom = () => {
    setGuestRoomsPopup(!guestRomsPopup);
  };
  const guestAndRoomsecond = () => {
    setGuestRoomsPopupsecond(!guestRomsPopupsecond);
  };
  const handleClickOutside = (event) => {
    if (ref1.current && !ref1.current.contains(event.target)) {
      setGuestRoomsPopup(false);
    }
    if (ref2.current && !ref2.current.contains(event.target)) {
      setGuestRoomsPopupsecond(false);
    }
    if (ref3.current && !ref3.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleHourscollect = (e) => {
    setGetHours(e.target.value);
    handleClose();
  };

  const handleIncrease = (type) => {
    if (type == "room") {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        rooms: guestRoomData.rooms + 1,
      }));
    } else if (type == "adult") {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        adults: guestRoomData.adults + 1,
      }));
    } else {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        child: guestRoomData.child + 1,
      }));
    }
  };

  const handleDecrease = (type) => {
    if (type == "room" && guestRoomData.rooms >= 1) {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        rooms: guestRoomData.rooms - 1,
      }));
    } else if (type == "adult" && guestRoomData.adults >= 1) {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        adults: guestRoomData.adults - 1,
      }));
    } else if (type == "children" && guestRoomData.child >= 1) {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        child: guestRoomData.child - 1,
      }));
    } else {
    }
  };

  useEffect(() => {
    if (selectPlaceDataHandleData != null) {
      setPlace(selectPlaceDataHandleData.place);
    }
  }, [selectPlaceDataHandleData]);

  useEffect(() => {
    hotelPlaceDataHandle({ place, place });
  }, []);
  useEffect(() => {
    if (selectPlaceDataHandleData?.place != undefined) {
      setPlace(selectPlaceDataHandleData?.place);
      setLongaddress(selectPlaceDataHandleData?.place);
    }
  }, [selectPlaceDataHandleData]);
  const searchsubmit = (event) => {
    event.preventDefault();

    if (place.length == 0) {
      setPlaceSearchError(t("headerSearch.search.PlaceValidationMsg"));
      return;
    } else {
      if (location.pathname == "/listingbidnow") {

        const filters = {
          price: [parseInt(searchFilterData.price[0]), parseInt(searchFilterData.price[1])],
          amenity: searchFilterData.amenity,
          star: searchFilterData.star,
        };
        const data = {
          language: languageToShow,
          filter: searchFilterAddOrNot,
          search_type: searchType,
          city: place != null ? place : "",
          book_for: getHours,
          check_in_date: moment(new Date(checkInOutValue.checkInTime.toString())).format(
            "DD/MM/YYYY"
          ),
          check_out_date:
            checkInOutValue.checkOutTime == null
              ? null
              : moment(new Date(checkInOutValue.checkOutTime.toString())).format(
                "DD/MM/YYYY"
              ),
          adults: guestRoomData.adults,
          children: guestRoomData.child,
          rooms: guestRoomData.rooms,
          sort_by: searchFilterData.shortby,
          filters: encodeURIComponent(JSON.stringify(filters)),
        };
        const dataSearch = {
          place: place,
          roomdata: guestRoomData,
          getHours: getHours,
          searchType: searchType,
          searchButtonShow: searchType,
          checkInTime: moment(new Date(checkInOutValue.checkInTime.toString())).format(
            "DD/MM/YYYY"
          ),
          checkOutTime:
            checkInOutValue.checkOutTime != null
              ? moment(new Date(checkInOutValue.checkOutTime.toString())).format(
                "DD/MM/YYYY"
              )
              : null,
        };

        placeStateDataSave(place);
        searchDataSave(dataSearch);
        getHotelListRequest(data);
      } else {

        navigate(
          {
            pathname: "/listingbidnow",
            search: createSearchParams({
              search_type: "hour",
              city: place != "" ? place : "",
              book_for: getHours,
              check_in_date: moment(
                new Date(checkInOutValue.checkInTime.toString())
              ).format("DD/MM/YYYY"),
              adults: guestRoomData.adults,
              children: guestRoomData.child,
              rooms: guestRoomData.rooms,
            }).toString(),
          },
          {
            state: {
              search_type: "hour",
              city: place != "" ? place : "",
              book_for: getHours,
              check_in_date: moment(
                new Date(checkInOutValue.checkInTime.toString())
              ).format("DD/MM/YYYY"),
              adults: guestRoomData.adults,
              children: guestRoomData.child,
              rooms: guestRoomData.rooms,
            },
          },
          { replace: true }
        );
      }
    }
  };

  const bidsearchnow = (event) => {
    event.preventDefault();
    var startDate = moment(new Date(selectFavouriteHotelSearchData.checkInTime));
    if (place.length == 0) {
      setPlaceSearchError(t("headerSearch.search.PlaceValidationMsg"));
      return;
    }
    if (selectFavouriteHotelSearchData.checkOutTime == null || selectFavouriteHotelSearchData.checkOutTime == "Invalid date") {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: '#296da9',
        text: t("headerSearch.search.checkoutdateValidationMsg")
      });

      return;
    }
    else {
      var endDate = moment(new Date(selectFavouriteHotelSearchData.checkOutTime));
    }



    if (endDate == null) {


    }
    else if (endDate <= startDate) {
      MySwal.fire({
        confirmButtonText: 'OK',
        confirmButtonColor: "#5287b3",
        text: t("headerSearch.search.dateValidationMsg")
      });
      //  errorToast(t("headerSearch.search.dateValidationMsg"));
      return;
    }

    if (location.pathname == "/listingbidnow") {

      const filters = {
        price: [parseInt(searchFilterData.price[0]), parseInt(searchFilterData.price[1])],
        amenity: searchFilterData.amenity,
        star: searchFilterData.star,
      };
      const data = {
        language: languageToShow,
        filter: searchFilterAddOrNot,
        search_type: searchType,
        city: place != null ? place : "",
        book_for: getHours,
        check_in_date: moment(new Date(checkInOutValue.checkInTime.toString())).format(
          "DD/MM/YYYY"
        ),
        check_out_date: moment(
          (checkInOutValue.checkOutTime == null
            ? null
            : new Date(checkInOutValue.checkOutTime
            ).toString())
        ).format("DD/MM/YYYY"),
        adults: guestRoomData.adults,
        children: guestRoomData.child,
        rooms: guestRoomData.rooms,
        sort_by: searchFilterData.shortby,

        filters: encodeURIComponent(JSON.stringify(filters)),
      };
      const dataSearch = {
        place: place,
        roomdata: guestRoomData,
        getHours: getHours,
        searchType: searchType,
        searchButtonShow: searchType,
        checkInTime: moment(new Date(checkInOutValue.checkInTime.toString())).format(
          "DD/MM/YYYY"
        ),
        checkOutTime:
          checkInOutValue.checkOutTime != null
            ? moment(new Date(checkInOutValue.checkOutTime.toString())).format(
              "DD/MM/YYYY"
            )
            : null,
      };
      placeStateDataSave(place);
      searchDataSave(dataSearch);
      getHotelListRequest(data);

    } else {
      navigate(
        {
          pathname: "/listingbidnow",
          search: createSearchParams({
            search_type: "bid",
            city: place != "" ? place : "",
            check_in_date: moment(
              new Date(checkInOutValue.checkInTime.toString())
            ).format("DD/MM/YYYY"),
            check_out_date: moment(
              (checkInOutValue.checkOutTime == null
                ? null
                : new Date(checkInOutValue.checkOutTime
                ).toString())
            ).format("DD/MM/YYYY"),
            adults: guestRoomData.adults,
            children: guestRoomData.child,
            rooms: guestRoomData.rooms,
          }).toString(),
        },
        {
          state: {
            search_type: "bid",
            city: place != "" ? place : "",
            check_in_date: moment(
              new Date(checkInOutValue.checkInTime.toString())
            ).format("DD/MM/YYYY"),
            check_out_date: moment(
              (checkInOutValue.checkOutTime == null
                ? null
                : new Date(checkInOutValue.checkOutTime
                ).toString())
            ).format("DD/MM/YYYY"),
            adults: guestRoomData.adults,
            children: guestRoomData.child,
            rooms: guestRoomData.rooms,
          },
        },
        { replace: true }
      );
    }
  };
  const dateFormat = "YYYY-MM-DD";
  const handleSelectt = (key) => {
    if (key === "first") {
      setSearchType("hour");
    } else {
      setSearchType("bid");
    }
  };
  useEffect(() => {
    if (selectHotelBidNowPageRouteData == "BiddingDetails") {
    } else {
      const dataSearch = {
        place: place,
        roomdata: guestRoomData,
        getHours: getHours,
        searchType: searchType,
        searchButtonShow: searchtypeShowButton,
        checkInTime: moment(new Date(checkInOutValue.checkInTime.toString())).format(
          "DD/MM/YYYY"
        ),
        checkOutTime:
          checkInOutValue.checkOutTime != null
            ? moment(new Date(checkInOutValue.checkOutTime.toString())).format(
              "DD/MM/YYYY"
            )
            : null,
      };
      searchDataSave(dataSearch);
    }
  }, [
    guestRoomData,
    getHours,
    searchType,
    checkInOutValue,
    value,
    longaddress,
    selectHotelBidNowPageRouteData,
  ]);

  const hendleDatePickercheckInClose = () => {
    setCheckIn(false);
    setCheckoutTime(true);
  };
  return (
    <>
      <Tabs
        defaultActiveKey={
          searchParams.get("search_type") == "hour" ? "first" : "second"
        }
        onSelect={handleSelectt}
      >
        <Tab eventKey="first" title={t("headerSearch.search.HourStay")}>
          <div className="tab-container hourly-stay">
            <form onSubmit={searchsubmit}>
              <div className="form-content">
                <div className="left">
                  <Form.Label className={`${longaddress == "" ? 'add-width' : ""}`}>
                    {longaddress == ""
                      ? `${t("headerSearch.search.WHERE")}`
                      : null}
                  </Form.Label>
                  <Form.Group className="form-box location-drop">
                    <AutocompleteReact />
                  </Form.Group>
                  <Form.Group className="form-box checkin">
                    {!isMobile ?

                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="datepickercheck-in"
                          label={`${t("headerSearch.search.CheckinLabel")}`}
                          value={checkInOutValue.checkInTime}
                          onChange={(newValue) => {
                            setCheckInOutValue((checkInOutValue) => ({
                              ...checkInOutValue,
                              checkInTime: newValue,
                            }));
                          }}
                          disablePast
                          renderInput={(params) => <TextField {...params} />}
                          onClose={() => {
                            setIsOpen(false);
                            handleOpen();

                          }}
                          KeyboardButtonProps={{
                            onClick: (e) => {
                              setIsOpen(true);
                            },
                          }}
                          InputProps={{
                            onClick: () => {
                              setIsOpen(true);
                            },
                          }}
                          open={isOpen}
                          inputFormat="DD MMM YYYY"
                          disableMaskedInput={true}
                        />
                      </LocalizationProvider>
                      :
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MobileDatePicker


                          label={`${t("home.search.CheckinLabel")}`}
                          value={checkInOutValue.checkInTime}
                          onChange={(newValue) => {
                            setCheckInOutValue((checkInOutValue) => ({
                              ...checkInOutValue,
                              checkInTime: newValue,
                            }));
                          }}
                          onClose={() => {
                            setIsOpen(false);
                            handleOpen();

                          }}
                          disablePast
                          renderInput={(params) => <TextField {...params} />}
                          inputFormat="DD MMM YYYY"
                          disableMaskedInput={true}
                        />
                      </LocalizationProvider>


                    }

                    {!isMobile ?
                      <div
                        className="pophours"
                        ref={ref3}
                        style={open ? { display: "block" } : { display: "none" }}
                      >
                        <Box sx={style}>

                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                            className="hourlybook"
                            onChange={handleHourscollect}
                          >
                            {t("headerSearch.search.BookFor")}
                            <Form.Select aria-label="Default select example">
                              <option>{t("headerSearch.search.Hours")}</option>
                              <option value="3">
                                {" "}
                                {t("headerSearch.search.Hour3")}
                              </option>
                              <option value="6">
                                {t("headerSearch.search.Hours6")}
                              </option>
                              <option value="12">
                                {t("headerSearch.search.Hours12")}
                              </option>
                            </Form.Select>
                          </Typography>
                        </Box>
                      </div>
                      :
                      <div
                        className="pophours"
                        style={
                          open
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <Box sx={style}>

                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                            className="hourlybook"
                            onChange={handleHourscollect}
                          >
                            {t("home.search.BookFor")}
                            <Form.Select aria-label="Default select example">
                              <option>
                                {/* Hours */}
                                {t("home.search.Hours")}
                              </option>
                              <option value="3">
                                {t("home.search.Hour3")}
                              </option>
                              <option value="6">
                                {t("home.search.Hours6")}
                              </option>
                              <option value="12">
                                {t("home.search.Hours12")}
                              </option>
                            </Form.Select>
                          </Typography>
                        </Box>
                      </div>

                    }

                    {getHours != null && getHours > 0 ? (
                      <span className="hourlable">
                        {getHours == 3
                          ? t("headerSearch.search.Hour3")
                          : getHours == 6
                            ? t("headerSearch.search.Hours6")
                            : t("headerSearch.search.Hours12")}

                      </span>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="form-box pop-form">
                    <Form.Control
                      type="text"
                      className="room"


                      placeholder={`${guestRoomData.adults + guestRoomData.child
                        } ${guestRoomData.adults + guestRoomData.child > 1
                          ? `${t("headerSearch.search.Guests")}`
                          : `${t("headerSearch.search.Guest")}`
                        }  / ${guestRoomData.rooms} ${guestRoomData.rooms > 1
                          ? `${t("headerSearch.search.Rooms")}`
                          : `${t("headerSearch.search.Room")}`
                        }
                      
                      `}
                      onClick={guestAndRoom}
                    />
                    <div
                      className="guestpop"
                      ref={ref1}
                      style={
                        guestRomsPopup
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <h3>
                        {/* Add Guest(s) And Room(s) */}
                        {t("headerSearch.search.AddGuest(s)andRoom(s)")}
                      </h3>
                      <ul>
                        <li>
                          <h4>
                            {/* Room (s) */}
                            {t("headerSearch.search.Room(s)")}
                          </h4>
                          <div className="counting">
                            <Button
                              onClick={() => {
                                handleDecrease("room");
                              }}
                            >
                              <AiOutlineMinusCircle />
                            </Button>
                            {guestRoomData.rooms}
                            <Button
                              onClick={() => {
                                handleIncrease("room");
                              }}
                            >
                              <BsPlusCircle />
                            </Button>
                          </div>


                        </li>
                        <li>
                          <h4>
                            {/* Adult (s)  */}
                            {t("headerSearch.search.Adult(s)")}
                          </h4>
                          <div className="counting">
                            <Button
                              onClick={() => {
                                handleDecrease("adult");
                              }}
                            >
                              <AiOutlineMinusCircle />
                            </Button>
                            {guestRoomData.adults}
                            <Button
                              onClick={() => {
                                handleIncrease("adult");
                              }}
                            >
                              <BsPlusCircle />
                            </Button>
                          </div>
                        </li>
                        <li>
                          <h4>
                            {/* Children (s)  */}
                            {t("headerSearch.search.Children(s)")}
                            <span>
                              {/* Max 11 years */}
                              {t("headerSearch.search.MaxYears11")}
                            </span>
                          </h4>
                          <div className="counting">
                            <Button
                              onClick={() => {
                                handleDecrease("children");
                              }}
                            >
                              <AiOutlineMinusCircle />
                            </Button>
                            {guestRoomData.child}
                            <Button
                              onClick={() => {
                                handleIncrease("children");
                              }}
                            >
                              <BsPlusCircle />
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </div>

                  </Form.Group>
                </div>
                <div className="right" style={{ cursor: 'pointer' }} onClick={() => document.getElementById('magnifier-btn').click()}>
                  <button id="magnifier-btn" type="submit"></button>
                </div>
              </div>
            </form>
          </div>
        </Tab>
        <Tab
          eventKey="second"
          onClick={() => {
            setSearchType("bid");
          }}
          title={t("headerSearch.search.BidNow")}
        >
          <div className="tab-container bid-now">
            <form onSubmit={bidsearchnow}>
              <div className="form-content">
                <div className="left">
                  <Form.Label className={`${longaddress == "" ? 'add-width' : ""}`}>
                    {longaddress == ""
                      ? `${t("headerSearch.search.WHERE")}`
                      : null}
                  </Form.Label>
                  <Form.Group className="form-box location-drop">

                    <AutocompleteReact />
                  </Form.Group>
                  <div className="bidchecking">
                    <Form.Group className="form-box">
                      {!isMobile ?
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="datepickercheck-in check-in"
                            label={t("headerSearch.search.CheckinLabel")}
                            value={checkInOutValue.checkInTime}
                            onChange={(newValue) => {
                              // setValue();
                              setCheckInOutValue((checkInOutValue) => ({
                                ...checkInOutValue,
                                checkInTime: newValue,
                              }));
                            }}
                            disablePast
                            renderInput={(params) => <TextField {...params} />}
                            onClose={() => {
                              hendleDatePickercheckInClose();
                            }}
                            KeyboardButtonProps={{
                              onClick: (e) => {
                                setCheckIn(true);
                              },
                            }}
                            InputProps={{
                              onClick: () => {
                                setCheckIn(true);
                              },
                            }}
                            open={checkIn}
                            inputFormat="DD MMM YYYY"
                            disableMaskedInput={true}
                          />
                        </LocalizationProvider>
                        : <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            className="datepickercheck-in check-in"
                            label={`${t("home.search.CheckinLabel")}`}
                            value={checkInOutValue.checkInTime}
                            onChange={(newValue) => {
                              // setValue();
                              moment(new Date(newValue)).format(
                                "YYYY-MM-DD HH:mm:ss"
                              );
                              setCheckInOutValue((checkInOutValue) => ({
                                ...checkInOutValue,
                                checkInTime: newValue,
                              }));
                            }}

                            disablePast
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="DD MMM YYYY"
                            disableMaskedInput={true}
                          />
                        </LocalizationProvider>
                      }

                    </Form.Group>
                    <Form.Group className="form-box check-out">
                      {!isMobile ?
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            className="datepickercheck-in"
                            label={t("headerSearch.search.CheckoutLabel")}
                            value={checkInOutValue.checkOutTime}
                            onChange={(newValue) => {
                              //setValue(moment(newValue).format("DD/MM/YYYY"));
                              setCheckInOutValue((checkInOutValue) => ({
                                ...checkInOutValue,
                                checkOutTime: newValue,
                              }));
                            }}
                            renderInput={(params) => <TextField {...params} />}
                            onClose={() => setCheckoutTime(false)}
                            KeyboardButtonProps={{
                              onClick: (e) => {
                                setCheckoutTime(true);
                              },
                            }}
                            disablePast
                            minDate={new Date(
                              checkInOutValue.checkInTime
                            ).setDate(
                              new Date(checkInOutValue.checkInTime).getDate() + 1
                            )}
                            InputProps={{
                              onClick: () => {
                                setCheckoutTime(true);
                              },
                            }}
                            open={checkoutTime}
                            inputFormat="DD MMM YYYY"
                            disableMaskedInput={true}
                          />
                        </LocalizationProvider>
                        : <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileDatePicker
                            className="datepickercheck-in"
                            label={`${t("home.search.CheckoutLabel")}`}
                            value={checkInOutValue.checkOutTime}
                            onChange={(newValue) => {
                              //setValue(moment(newValue).format("DD/MM/YYYY"));
                              setCheckInOutValue((checkInOutValue) => ({
                                ...checkInOutValue,
                                checkOutTime: newValue,
                              }));
                            }}
                            minDate={new Date(
                              checkInOutValue.checkInTime
                            ).setDate(
                              new Date(checkInOutValue.checkInTime).getDate() + 1
                            )}
                            renderInput={(params) => (
                              <TextField {...params} />
                            )}
                            disablePast

                            inputFormat="DD MMM YYYY"
                            disableMaskedInput={true}
                          />
                        </LocalizationProvider>
                      }

                    </Form.Group>
                  </div>
                  <Form.Group className="form-box pop-form">
                    <Form.Control
                      type="text"
                      className="room"

                      placeholder={`${guestRoomData.adults + guestRoomData.child
                        } ${guestRoomData.adults + guestRoomData.child > 1
                          ? t("headerSearch.search.Guests")
                          : t("headerSearch.search.Guest")
                        }  / ${guestRoomData.rooms} ${guestRoomData.rooms > 1
                          ? t("headerSearch.search.Rooms")
                          : t("headerSearch.search.Room")
                        }`}
                      onClick={guestAndRoomsecond}
                    />
                    <div
                      className="guestpop"
                      ref={ref2}
                      style={
                        guestRomsPopupsecond
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <h3>
                        {/* Add Guest (s) And Room (s) */}
                        {t("headerSearch.search.AddGuest(s)andRoom(s)")}
                      </h3>
                      <ul>
                        <li>
                          <h4>
                            {/* Room (s) */}
                            {t("headerSearch.search.Room(s)")}
                          </h4>
                          <div className="counting">
                            <Button
                              onClick={() => {
                                handleDecrease("room");
                              }}
                            >
                              <AiOutlineMinusCircle />
                            </Button>
                            {guestRoomData.rooms}
                            <Button
                              onClick={() => {
                                handleIncrease("room");
                              }}
                            >
                              <BsPlusCircle />
                            </Button>
                          </div>
                        </li>
                        <li>
                          <h4>
                            {/* Adult (s)  */}
                            {t("headerSearch.search.Adult(s)")}
                          </h4>
                          <div className="counting">
                            <Button
                              onClick={() => {
                                handleDecrease("adult");
                              }}
                            >
                              <AiOutlineMinusCircle />
                            </Button>
                            {guestRoomData.adults}
                            <Button
                              onClick={() => {
                                handleIncrease("adult");
                              }}
                            >
                              <BsPlusCircle />
                            </Button>
                          </div>
                        </li>
                        <li>
                          <h4>
                            {/* Children (s)  */}
                            {t("headerSearch.search.Children(s)")}

                            <span>
                              {/* Max 11 years */}
                              {t("headerSearch.search.MaxYears11")}
                            </span>
                          </h4>
                          <div className="counting">
                            <Button
                              onClick={() => {
                                handleDecrease("children");
                              }}
                            >
                              <AiOutlineMinusCircle />
                            </Button>
                            {guestRoomData.child}
                            <Button
                              onClick={() => {
                                handleIncrease("children");
                              }}
                            >
                              <BsPlusCircle />
                            </Button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Form.Group>
                </div>
                <div className="right" style={{ cursor: 'pointer' }} onClick={() => document.getElementById('magnifier-btn').click()}>
                  <button id="magnifier-btn" type="submit"></button>
                  {/* {t("headerSearch.search.searchBtn")} */}
                </div>
              </div>
            </form>
          </div>
        </Tab>
      </Tabs>
      <p style={{ color: "red1" }}>{placeSearchError}</p>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  searchFilterData: searchFilterData,
  searchFilterAddOrNot: searchFilterAddOrNot,
  selectFavouriteHotelSearchData: selectFavouriteHotelSearchData,
  selectHotelDetailsPageRouteData: selectHotelDetailsPageRouteData,
  selectHotelBidNowPageRouteData: selectHotelBidNowPageRouteData,
  selectPlaceDataHandleData: selectPlaceDataHandleData,
});

const mapDispatchToProps = (dispatch) => ({
  searchDataSave: (data) => dispatch(searchDataSave(data)),
  getHotelListRequest: (data) => dispatch(getHotelListRequest(data)),
  placeStateDataSave: (data) => dispatch(placeStateDataSave(data)),
  hotelPlaceDataHandle: (data) => dispatch(hotelPlaceDataHandle(data)),
  hotelWheatherApiRequest: (data) => dispatch(hotelWheatherApiRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HotelSearchForOthers);
