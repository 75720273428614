import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";

const MyNotificationsList = ({ myNotificationsList }) => {
  var collecSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="bookingbox">
      <div className="bookingboxlft">
        <p>
          {" "}
          Date{" "}
          <span>
            {moment(new Date(myNotificationsList.expiry_timestamp)).format("ll")}
          </span>
        </p>
        <h3>
          {myNotificationsList.notification_title
            ? myNotificationsList.notification_title
            : "New Points Added"}
        </h3>
        <p>
          {myNotificationsList.notification_title
            ? myNotificationsList.notification_description
            : "Points added to your account. Redeem for best deal."}
        </p>
      </div>

    </div>
  );
};

export default MyNotificationsList;
