import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import useScript from "react-script-hook";
import { createStructuredSelector } from "reselect";
import {
  hotelGeoLocationAdded,
  hotelPlaceDataHandle,
} from "./../../redux/hotels/hotel.actions";
import {
  selectGeolocationData,
  selectPlaceDataHandleData,
} from "./../../redux/hotels/hotel.selectors";

const AutocompleteReact = ({
  hotelPlaceDataHandle,
  selectPlaceDataHandleData,
  hotelGeoLocationAdded,
  selectGeolocationData,
  placeHolderKey = " the city or hotel name", // Change placeHolder to placeHolderKey
  focusCheckinDate = () => {},
}) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("common"); // Use t and i18n from useTranslation hook
  const [inputValue, setInputValue] = useState("");
  const [loading, error] = useScript({
    src:
      "https://maps.googleapis.com/maps/api/js?key=" +
      process.env.REACT_APP_GoogleAPIKEY +
      "&language=en&libraries=places&callback=Function.prototype",
    checkForExisting: true,
  });
  const [longaddress, setLongaddress] = useState(
    selectPlaceDataHandleData != null
      ? selectPlaceDataHandleData.longaddress
      : searchParams.get("city") == null
      ? ""
      : searchParams.get("city")
  );
  const autoCompleteRef = useRef();
  const [place, setPlace] = useState(
    selectPlaceDataHandleData != null
      ? selectPlaceDataHandleData?.place
      : searchParams.get("city") == null
      ? "Dubai"
      : searchParams.get("city")
  );
  const inputRef = useRef();
  let location = useLocation();

  const handleChangebid = (e) => {
    setInputValue(e.target.value);
    setLongaddress(e.target.value);
    setPlace(e.target.value);
  };

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
  };
  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
  };

  useEffect(() => {
    if (!loading) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        if (place != undefined) {
          setLongaddress(place.name);
        }
        if (place != undefined) {
          place.address_components.map((address) => {
            if (address.types[0] == "locality") {
              setPlace(address.long_name);
              hotelGeoLocationAdded(true);
            }
          });
        }
      });
    }
  }, [loading]);

  // const handleInputFocus = () => {
  //     setInputValue('');
  // };

  useEffect(() => {
    if (location.pathname == "/" && selectGeolocationData == false) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        let response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GoogleAPIKEY}`
        );
        let data = await response.json();

        const city = data.results
          .find((result) => result.types.includes("locality"))
          ?.address_components.find((component) =>
            component.types.includes("locality")
          )?.long_name;

        if (city) {
          setPlace(city);
          setLongaddress(city);
        }

        data?.results[1]?.address_components.map((addressComponent) => {
          if (addressComponent.types[0] == "locality") {
            setPlace(addressComponent.short_name);
            setLongaddress(addressComponent.short_name);
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    hotelPlaceDataHandle({ longaddress, place });
  }, [longaddress, place]);

  useEffect(() => {
    if (selectPlaceDataHandleData?.longaddress) {
      focusCheckinDate?.();
    }
  }, [selectPlaceDataHandleData?.longaddress]);

  return (
    <div>
      {!loading ? (
        <div className="place-container">
          <span
            className={
              inputValue === "" && selectPlaceDataHandleData?.longaddress == ""
                ? "placehold-first"
                : "placehold-first-hide"
            }
            style={{ fontWeight: "bold" }}
          >
            {t("loginAndRegisterPage.Enter")}
          </span>
          <input
            ref={inputRef}
            value={selectPlaceDataHandleData?.longaddress}
            onKeyDown={handleInputValue}
            onChange={handleChangebid}
            placeholder={t("loginAndRegisterPage.CityOrName")}
          />
        </div> // Use t function to translate placeholder
      ) : (
        "Loading"
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectPlaceDataHandleData: selectPlaceDataHandleData,
  selectGeolocationData: selectGeolocationData,
});
const mapDispatchToProps = (dispatch) => ({
  hotelPlaceDataHandle: (data) => dispatch(hotelPlaceDataHandle(data)),
  hotelGeoLocationAdded: (data) => dispatch(hotelGeoLocationAdded(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteReact);
