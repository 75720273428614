import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import {
  emailSignInStart,
  stateClearAfterTask,
  validateEmailStateClearAfterTask
} from "./../../redux/user/user.actions";
import {
  selectCurrentUser,
  selectSignInError,
  selectSinInLoading,
  selectSaveGuestUserCheckoutData,
} from "../../redux/user/user.selectors";

import { createStructuredSelector } from "reselect";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
const LoginForm = ({
  currentUser,
  emailSignInStart,
  signinerror,
  stateClear,
  signInLoading,
  saveGuestUserCheckoutData,
  handleCloseLoginPopup,
  search_type,
  validateEmailStateClearAfterTask
}) => {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const location = useLocation();
  const currentLocation = useLocation();
  const [eye, seteye] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const [userDataError, setuserDataError] = useState({
    emailErr: "",
    passwordErr: "",
  });
  useEffect(() => {
    if (signinerror != null) {
      validateEmailStateClearAfterTask();

      stateClear();
    }
  }, [signinerror]);


  const handleChange = (e) => {
    if (e.target.name == "email") {
      setuserDataError({
        ...userDataError,
        emailErr: "",
      });
    } else if (e.target.name == "password") {
      setuserDataError({
        ...userDataError,
        passwordErr: "",
      });
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var phoneno = /^\d{11}$/;
    if (userData.email == "") {
      setuserDataError({
        ...userDataError,
        emailErr: t("loginAndRegisterPage.validationMsg.PleaseEnterYourEmailOrPhoneNo"),
      });
      return;
    } else if (userData.password == "") {
      setuserDataError({
        ...userDataError,
        passwordErr: t("loginAndRegisterPage.validationMsg.PleaseEnterYourPassword"),
      });
      return;
    } else {
      if (userData.email.match(phoneno)) {
        var data = {
          contact_no: userData.email,
          password: userData.password,
        };
      } else {
        var data = {
          email: userData.email,
          password: userData.password,
        };
      }
      emailSignInStart(data);
    }
  };

  const handlepasswordhideshow = () => {
    seteye(!eye);
  };

  useEffect(() => {
    
    if (currentUser != null) {
      if (currentLocation.pathname == "/login" || currentLocation.pathname == "/sign-up" ) {
        navigate("/");
      } else {
     
      }
    }
  }, [currentUser]);


  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicEmail">
          <Form.Label>{t("loginAndRegisterPage.EmailOrMobileNumber")} *</Form.Label>
          <Form.Control
            type="text"
            placeholder={t("loginAndRegisterPage.Emailaddress")}
            onChange={handleChange}
            name="email"
            value={userData.email}
          />
          
          <Form.Text className="text-muted errorformmessage">
            {userDataError.emailErr}
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formBasicPassword">
          <Form.Label>{t("loginAndRegisterPage.Password")} *</Form.Label>
          <div className="passwordsec">
            <Form.Control
              type={eye ? "text" : "password"}
              placeholder={t("loginAndRegisterPage.Password")}
              onChange={handleChange}
              name="password"
              value={userData.password}
            />
            {eye ? (
              <AiOutlineEye onClick={handlepasswordhideshow} />
            ) : (
              <AiOutlineEyeInvisible onClick={handlepasswordhideshow} />
            )}
            <Form.Text className="text-muted errorformmessage">
              {userDataError.passwordErr}
            </Form.Text>
          </div>
          <div className="forgetpass">
            <Link to="/forgot-password">{t("loginAndRegisterPage.ForgotPassword")}</Link>
          </div>
        </Form.Group>
        <input type="hidden" id="zc_gad" name="zc_gad" value="1234"/>
        
        <Button variant="primary" type="submit" className="formsubmit">
          {signInLoading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            t("loginAndRegisterPage.LOGIN")
          )}
        </Button>
      </Form>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  signinerror: selectSignInError,
  signInLoading: selectSinInLoading,
  saveGuestUserCheckoutData: selectSaveGuestUserCheckoutData,
});
const mapDispatchToProps = (dispatch) => ({
  emailSignInStart: (data) => dispatch(emailSignInStart(data)),
  stateClear: () => dispatch(stateClearAfterTask()),
  validateEmailStateClearAfterTask: () => dispatch(validateEmailStateClearAfterTask())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
