import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiLogIn } from "react-icons/fi";
import { BiUserCircle } from "react-icons/bi";
import profilePic from "../../../assets/images/ProfilePic.png";
import Topnav from "../../../assets/images/topnav.svg";
import {
  setUserLocal,
} from "../../../config/common.api";
import { createStructuredSelector } from "reselect";
import { useTranslation } from "react-i18next";
import {
  selectSocialLoginUser,
  selectUserLoginData,
} from "../../../redux/user/user.selectors";
import { UserLogoutStart } from "../../../redux/user/user.actions";
import { connect } from "react-redux";
import { selectLanguage } from "./../../../redux/language/language.actions";
import { selectlanguageToShow } from "./../../../redux/language/language.selectors";
import { selectCurrency } from "./../../../redux/currency/currency.actions";
import { selectcurrencyToShow } from "./../../../redux/currency/currency.selectors";
import { selectHotelWhetherData } from "./../../../redux/hotels/hotel.selectors";
import wfrleeLogo from "../../../assets/images/logo.svg";
import { CurrencyConverterHook } from "../../../utils/CurrencyConverter";
import { useLocation, useNavigate } from "react-router-dom";
import { selectSaveGuestUserCheckoutData } from "../../../redux/hotels/hotel.selectors";
import {
  saveGuestUserCheckoutDataRequest,
  stateClearAfterTask,
  getHourlyCheckoutDetailsRequest,
  getBidCheckoutDetailsRequest,
} from "../../../redux/hotels/hotel.actions";
import LoginModal from "../../../components/checkout/LoginModal";
import LoginPage from "../../../pages/Login/Login";
import axios from "axios";
import GoogleTranslate from "../../common/googleTranslate/GoogleTranslate";
import Popper from "@mui/material/Popper";
import $ from "jquery";
import CustomPopupModal from "../../modals/CustomPopupModal";
import currencies from "../../../utils/currency.json";
import store from "./../../../redux/store";
const Header = ({
  userData,
  selectLanguage,
  languageToShow,
  selectCurrency,
  currencyToShow,
  userAuthData,
  UserLogoutStart,
  selectHotelWhetherData,
  saveGuestUserCheckoutDataRequest,
  stateClearAfterTask,
  saveGuestUserCheckoutData,
  getBidCheckoutDetailsRequest,
  getHourlyCheckoutDetailsRequest,
}) => {

  const { currencyConverted } = CurrencyConverterHook();
  const navigate = useNavigate();
  var [showLoginPopup, setShowLoginPopup] = useState(false);
  const [convertedText, setConvertedText] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const popperRef = React.useRef();
  var [show, setShow] = useState(false);
  var handleClose = () => setShow(false);
  var handleShow = () => setShow(!show);
  const [t, i18n] = useTranslation("common");
  const location = useLocation();
  const currentLocation = useLocation();

  const handleLogout = () => {
    const data = null;
    setUserLocal("");
    localStorage.clear();
    UserLogoutStart();
    saveGuestUserCheckoutDataRequest(data);
    stateClearAfterTask();
    navigate("/");

  };

  const handleSelect = (e) => {
    setOpen(false);
    selectLanguage(e);
    i18n.changeLanguage(e);

    if (
      e == "en" ||
      (e == "ar" && $("select.goog-te-combo option").val() !== "")
    ) {
      dismissGoogleTranslate();
    }
  };

  const handleSelectCurrency = async (e, prev) => {
    let setCur = { current: e, prev: prev };
    const getConvertedCurrencyValue = await currencyConverted(setCur);
    if (
      "status" in getConvertedCurrencyValue &&
      getConvertedCurrencyValue.status === 200 &&
      "data" in getConvertedCurrencyValue &&
      "rates" in getConvertedCurrencyValue.data
    ) {
      setCur.convertedRates = getConvertedCurrencyValue.data.rates[e];
    }
    selectCurrency(setCur);
  };

  useEffect(() => {
    if (languageToShow == "ar") {
      i18n.changeLanguage(languageToShow);
      document.body.classList.add("arabic-lang");
    } else {
      i18n.changeLanguage(languageToShow);
      document.body.classList.remove("arabic-lang");
    }
  }, [languageToShow]);

  const selectedLang = $(".goog-te-combo option:selected").text();

  const handleCloseLoginPopup = () => {
    setShowLoginPopup(false);
  };

  // Token expireation code Below

  useEffect(() => {
    const backendUrlEndPoint = process.env.REACT_APP_BACKEND_API_ENDPOINT;
    axios.post(`${backendUrlEndPoint}/user/validate`, {
    "":"",
    }, {  headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization:  store.getState().user.currentUser != null
      ? "Bearer " + store.getState().user.currentUser.token + ""
      : "",
    }})
    .then((response) => {
      if(response.data.data.expired) {
        const data = null;
        setUserLocal("");
        localStorage.clear();
        UserLogoutStart();
        saveGuestUserCheckoutDataRequest(data);
        stateClearAfterTask();
       // navigate("/");
      } else {
       
      }
    });
  
   },[]); 



  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  /** call below method to reset google translate ** */
  function dismissGoogleTranslate() {
    // find `iframe` element with GoogleTranslate select and buttons
    var iframe =
      document.getElementsByClassName("goog-te-banner-frame")[0] ||
      document.getElementById(":1.container");
    if (!iframe) return;

    // search all buttons from the retrieved iframe
    var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
    var restore_el = innerDoc.getElementsByTagName("button");

    // fire `click` event on the `restore` button, that `Shows the origin`
    for (var i = 0; i < restore_el.length; i++) {
      if (restore_el[i].id.indexOf("restore") >= 0) {
        restore_el[i].click();
        return;
      }
    }
  }

  return (
    <>
      {/* Header Section */}
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="logo">
                <Link to="/">
                  <img src={wfrleeLogo} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-md-9">
              <div className="toprightsec">
                {(selectHotelWhetherData != null &&
                  location.pathname.includes("/listingbidnow")) ||
                (selectHotelWhetherData != null &&
                  location.pathname.includes("hotel-details")) ||
                (selectHotelWhetherData != null &&
                  location.pathname.includes("bidguestuser")) ||
                (selectHotelWhetherData != null &&
                  location.pathname.includes("hourlyguestuser")) ? (
                  <div className="topweather">
                    <span>{selectHotelWhetherData.location.name}</span>{" "}
                    <img src="./img/weathermeter.svg" alt="" />{" "}
                    <span>{selectHotelWhetherData.current.temp_c} &deg;C</span>
                  </div>
                ) : (
                  ""
                )}
                <ul>
                  {userAuthData != null ? (
                    <>
                      <li key={"1"} >
                        <span className="header_login_link">
                          
                          <img
                            src="./img/profile-circle.svg"
                            alt=""
                            width={23}
                          />{" "}
                          {t("header.greeting")} {userAuthData.first_name}
                          
                        </span>
                      </li>
                    </>
                  ) : (
                    <>
                      <li key={2}>
                        <span
                          onClick={() => {
                            if (
                              currentLocation.pathname == "/bidguestuser" ||
                              currentLocation.pathname == "/hourlyguestuser"
                            ) {
                              navigate("/login");
                             // setShowLoginPopup(true);
                            } else {
                              navigate("/login");
                            }
                          }}
                          className="header_login_link"
                        >
                          
                          <FiLogIn /> {t("header.Login")}
                          
                        </span>
                      </li>
                      <li key={3}>
                        <span
                          onClick={() => {
                            if (
                              currentLocation.pathname == "/bidguestuser" ||
                              currentLocation.pathname == "/hourlyguestuser"
                            ) {
                              navigate("/sign-up");
                             
                            } else {
                              navigate("/sign-up");
                            }
                          }}
                          className="header_login_link"
                        >
                          
                          <img
                            src="./img/profile-circle.svg"
                            alt=""
                            width={23}
                          />{" "}
                          {t("header.Register")}
                          
                        </span>
                      </li>
                    </>
                  )}
                  <li key={4} className="langdrop">
                    <Dropdown onSelect={handleSelect}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        className="notranslate"
                        translate="no"
                      >
                        
                        {languageToShow == "en"
                          ? "العربية"
                          : languageToShow == "ar"
                          ? "English"
                          : ""}
                        <img src="./img/belowarrow.svg" alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {languageToShow == "en" ? (
                          <Dropdown.Item
                            eventKey="ar"
                            className="notranslate"
                            translate="no"
                          >
                            العربية
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item
                            eventKey="en"
                            className="notranslate"
                            translate="no"
                          >
                            English
                          </Dropdown.Item>
                        )}
                        {selectedLang !== "Select Language" &&
                          selectedLang !== "" && (
                            <Dropdown.Item
                              eventKey="en"
                              className="notranslate"
                              translate="no"
                            >
                              English
                            </Dropdown.Item>
                          )}{" "}
                        
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={handleShow}
                          className="notranslate"
                          translate="no"
                          eventKey="en"
                          style={{
                            color:
                              selectedLang !== "Select Language" &&
                              selectedLang !== "" &&
                              languageToShow == "en"
                                ? "#3488cd"
                                : "",
                          }}
                        >
                          {selectedLang == "Select Language"
                            ? "Other Language"
                            : selectedLang
                            ? selectedLang
                            : "Other Language"}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li key={5}>
                    <Dropdown
                      onSelect={(e) =>
                        handleSelectCurrency(e, currencyToShow.current)
                      }
                    >
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        translate="no"
                      >
                        {currencyToShow.current == "SAR"
                          ? "SAR"
                          : currencyToShow.current}{" "}
                        <img src="./img/belowarrow.svg" alt="" />
                      </Dropdown.Toggle>
                      <div className="arrowBeforeMenu"></div>
                      <Dropdown.Menu className="currencyMenu">
                        {currencies.map((currency, i) => (
                          <Dropdown.Item
                            key={i}
                            eventKey={`${currency.symbol}`}
                            translate="no"
                          >
                            {currency.symbol}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li>
                    {userAuthData != null ? (
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <img src={Topnav} alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Link to="/myaccount">
                              {t("header.MyAccountDropdown.MyAccount")}
                          </Link><br/>
                          <Link to="/mybooking">
                              {t("header.MyAccountDropdown.MyBookings")}
                          </Link><br/>
                          <Link to="/mybidding">
                              {t("header.MyAccountDropdown.MyBiddings")}
                          </Link><br/>
                          <Link to="/mywallet">
                              {t("header.MyAccountDropdown.MyWallet")}
                          </Link><br/>
                          <Link to="/mypoints">
                              {t("header.MyAccountDropdown.MYWfrleePoints")}
                          </Link><br/>
                          
                          <Dropdown.Item onClick={handleLogout}>
                            {t("header.MyAccountDropdown.SignOut")}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          
          <CustomPopupModal
            show={show}
            handleClose={handleClose}
            setShow={setShow}
            headerTitle="Other Language Translation"
          >
            <GoogleTranslate />
          </CustomPopupModal>

          <LoginModal show={showLoginPopup} handleClose={handleCloseLoginPopup}>
            <LoginPage handleCloseLoginPopup={handleCloseLoginPopup} />
          </LoginModal>
        </div>
      </header>
    </>
  );
};
const mapStateToProps = createStructuredSelector({
  userData: selectSocialLoginUser,
  languageToShow: selectlanguageToShow,
  currencyToShow: selectcurrencyToShow,
  userAuthData: selectUserLoginData,
  selectHotelWhetherData: selectHotelWhetherData,
  saveGuestUserCheckoutData: selectSaveGuestUserCheckoutData,
});
const mapDispatchToProps = (dispatch) => ({
  selectLanguage: (data) => dispatch(selectLanguage(data)),
  selectCurrency: (data) => dispatch(selectCurrency(data)),
  UserLogoutStart: () => dispatch(UserLogoutStart()),
  saveGuestUserCheckoutDataRequest: (data) =>
    dispatch(saveGuestUserCheckoutDataRequest(data)),
  stateClearAfterTask: () => dispatch(stateClearAfterTask()),
  getBidCheckoutDetailsRequest: (data) =>
    dispatch(getBidCheckoutDetailsRequest(data)),
  getHourlyCheckoutDetailsRequest: (data) =>
    dispatch(getHourlyCheckoutDetailsRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
