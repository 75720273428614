import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { BsFillStarFill, BsPlusCircle, BsStar } from "react-icons/bs";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  getHomePageRandomReviewRequest,
  getOfferPromotionsPartnersPreferenceRequest,
  getPerferenceDataList,
} from "./../../redux/home/home.actions";

import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import "@reach/combobox/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SpecialSection from "../../components/special-section/SpecialSection";
import {
  selectRandomReview,
  selectofferLoading,
  selectofferspromotionsdestinationData,
} from "../../redux/home/home.selectors";
import {
  hotelOfferremoveHandlerDataList,
  hotelToggleAddOrNot,
} from "../../redux/hotels/hotel.actions";
import { selectlanguageToShow } from "../../redux/language/language.selectors";
import FlexibleStay from "./../../assets/images/Group1783.gif";
import Discounts from "./../../assets/images/Group1784.gif";
import BestHotels from "./../../assets/images/Group1785.gif";
import BidYourPrice from "./../../assets/images/Group1786.gif";
import {
  hotelOfferHandlerDataList,
  hotelPlaceDataHandle,
} from "./../../redux/hotels/hotel.actions";
import { selectPlaceDataHandleData } from "./../../redux/hotels/hotel.selectors";

const AutocompleteReact = React.lazy(() =>
  import("./../../components/rectInput-auto-complete/reactInput")
);

const StyledRating = styled(Rating)({
  "& .MuiRating-iconEmpty ": {
    color: "#eee",
    marginLeft: "5px",
  },
  "& .MuiRating-iconFilled": {
    color: "#FFC764",
    marginLeft: "5px",
  },
  "& .MuiRating-iconHover": {
    color: "#FFC764",
  },
});

var preference = {
  autoplay: true,
  autoplaySpeed: 1500,
  arrows: true,
  dots: false,
  slidesToShow: 5,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

var partner = {
  autoplay: true,
  autoplaySpeed: 1800,
  arrows: true,
  dots: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

var offerPromotion = {
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  dots: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const HomePage = ({
  getPerferenceDataList,
  hotelWheatherApiRequest,
  getOfferPromotionsPartnersPreferenceRequest,
  languageToShow,
  offersPromotionsDestinations,
  selectofferLoading,
  selectPlaceDataHandleData,
  hotelOfferHandlerDataList,
  hotelPlaceDataHandle,
  hotelToggleAddOrNot,
  getHomePageRandomReviewRequest,
  selectRandomReview,
  hotelOfferremoveHandlerDataList,
}) => {
  window.addEventListener("scroll", function () {
    //scrollCounter.innerHTML = window.pageYOffset;
  });
  AOS.init({
    offset: 200,
    duration: 800,
    easing: "ease-in-out-sine",
    delay: 200,
    mirror: true,
  });

  // increasing a day in tomorrow and setting it to tomorrow
  const today = new Date();
  // initializing tomorrow with today's date
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate());
  const dayAfterTomorrow = new Date(today);
  dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);

  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const firstRender = useRef(true);
  const datePickerRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [checkoutTime, setCheckoutTime] = useState(false);
  const [getHours, setGetHours] = useState(3);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [guestRomsPopup, setGuestRoomsPopup] = useState(false);
  const [guestRomsPopupsecond, setGuestRoomsPopupsecond] = useState(false);
  const [destinationsData, setDestinationsData] = useState(null);
  const [guestRoomData, setGuestRoomData] = useState({
    rooms: 1,
    child: 0,
    adults: 1,
  });
  const [t] = useTranslation("common");
  const [place, setPlace] = useState(selectPlaceDataHandleData?.place);
  const [longaddress, setLongaddress] = useState(
    selectPlaceDataHandleData?.place
  );
  const [placeSearchError, setPlaceSearchError] = useState("");
  const [checkIn, setCheckIn] = useState(false);
  const [checkInOutValue, setCheckInOutValue] = useState({
    checkInTime: `${moment(new Date(tomorrow)).format("YYYY-MM-DD")}`,
    checkOutTime: `${moment(new Date(dayAfterTomorrow)).format("YYYY-MM-DD")}`,
  });
  const [popularDestinationsTime] = useState({
    checkInTime: `${moment(new Date(tomorrow)).format("YYYY-MM-DD")}`,
    checkOutTime: `${moment(new Date(dayAfterTomorrow)).format("YYYY-MM-DD")}`,
  });
  const ref = useRef(null);
  const ref1 = useRef(null);
  const ref3 = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getHomePageRandomReviewRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* Popular Destination Navigation */

  const populartoleasting = (citynamedetails) => {
    const today = new Date();
    // initializing tomorrow with today's date
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate());
    const dayAfterTomorrow = new Date(today);
    dayAfterTomorrow.setDate(dayAfterTomorrow.getDate() + 1);
    hotelPlaceDataHandle({
      longaddress: citynamedetails,
      place: citynamedetails,
    });
    window.scroll(0, 0);

    navigate(
      {
        pathname: "listingbidnow",
        search: createSearchParams({
          search_type: "bid",
          city: citynamedetails,

          check_in_date: `${moment(
            new Date(popularDestinationsTime.checkInTime.toString())
          ).format("DD/MM/YYYY")}`,
          check_out_date: `${moment(
            new Date(popularDestinationsTime.checkOutTime.toString())
          ).format("DD/MM/YYYY")}`,
          adults: "1",
          children: "0",
          rooms: "1",
        }).toString(),
      },
      {
        state: {
          search_type: "bid",
          city: citynamedetails,

          check_in_date: `${moment(
            new Date(popularDestinationsTime.checkOutTime.toString())
          ).format("DD/MM/YYYY")}`,
          check_out_date: `${moment(
            new Date(popularDestinationsTime.checkOutTime.toString())
          ).format("DD/MM/YYYY")}`,
          adults: "1",
          children: "0",
          rooms: "1",
        },
      }
    );
  };
  /* Popular Destination Navigation */

  /* Date Picker Close After Input */
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (checkInOutValue.checkInTime !== null && isOpen === false) {
        handleOpen();
      }
    }
  }, [checkInOutValue.checkInTime, isOpen]);
  /* Date Picker Close After Input */

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  // DatePicker
  /* User Guest Room Modal PopUp Open */
  const guestAndRoom = () => {
    setGuestRoomsPopup(!guestRomsPopup);
  };
  const guestAndRoomsecond = () => {
    setGuestRoomsPopupsecond(!guestRomsPopupsecond);
  };
  /* User Guest Room Modal PopUp Open */

  /* User Guest Room Modal PopUp Close Click OutSide */
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setGuestRoomsPopup(false);
    }
    if (ref1.current && !ref1.current.contains(event.target)) {
      setGuestRoomsPopupsecond(false);
    }

    if (ref3.current && !ref3.current.contains(event.target)) {
      handleClose();
    }
  };
  /* User Guest Room Modal PopUp Close Click OutSide */

  /* User outside click popup should close */
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* User outside click popup should close */

  /* Handle Hours collect Through User Input */
  const handleHourscollect = (e) => {
    setGetHours(e.target.value);
    handleClose();
  };
  useEffect(() => {
    if (offersPromotionsDestinations != null) {
      setDestinationsData(offersPromotionsDestinations.destinations);
    }
  }, [offersPromotionsDestinations]);
  /* Handle Hours collect Through User Input */

  /* User Selected Room Data CalCulations Increase */
  const handleIncrease = (type) => {
    if (type === "room") {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        rooms: guestRoomData.rooms + 1,
      }));
    } else if (type === "adult") {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        adults: guestRoomData.adults + 1,
      }));
    } else {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        child: guestRoomData.child + 1,
      }));
    }
  };
  /* User Selected Room Data CalCulations Increase*/

  /* User Selected Room Data CalCulations Decrease*/
  const handleDecrease = (type) => {
    if (type === "room" && guestRoomData.rooms >= 1) {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        rooms: guestRoomData.rooms - 1,
      }));
    } else if (type === "adult" && guestRoomData.adults >= 1) {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        adults: guestRoomData.adults - 1,
      }));
    } else if (type === "children" && guestRoomData.child >= 1) {
      setGuestRoomData((existingValues) => ({
        ...existingValues,
        child: guestRoomData.child - 1,
      }));
    } else {
    }
  };
  /* User Selected Room Data CalCulations Decrease*/

  /* Search and Naviate By Hours */
  const searchsubmit = (event) => {
    event.preventDefault();

    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    if (longaddress === "") {
      setPlaceSearchError(t("home.search.PlaceValidationMsg"));
      return;
    } else if (place === "") {
      setPlaceSearchError(t("home.search.PlaceValidationMsg"));
      return;
    } else {
    }

    navigate(
      {
        pathname: "listingbidnow",
        search: createSearchParams({
          search_type: "hour",
          city: place !== "" ? place : "",
          book_for: getHours === 0 ? 3 : getHours,
          check_in_date:
            checkInOutValue.checkInTime == null
              ? "" + year + "-" + month + "-" + day + ""
              : moment(new Date(checkInOutValue.checkInTime.toString())).format(
                  "DD/MM/YYYY"
                ),
          adults: guestRoomData.adults,
          children: guestRoomData.child,
          rooms: guestRoomData.rooms,
        }).toString(),
      },

      {
        state: {
          search_type: "hour",
          city: place !== "" ? place : "",
          book_for: getHours,
          check_in_date:
            checkInOutValue.checkInTime == null
              ? "" + year + "-" + month + "-" + day + ""
              : moment(new Date(checkInOutValue.checkInTime.toString())).format(
                  "DD/MM/YYYY"
                ),
          adults: guestRoomData.adults,
          children: guestRoomData.child,
          rooms: guestRoomData.rooms,
        },
      }
    );
  };
  /* Search and Naviate By Hours */

  /* Search and Naviate By Date */
  const bidsearchnow = (event) => {
    event.preventDefault();
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let nextday = dateObj.getUTCDate() + 1;
    let year = dateObj.getUTCFullYear();

    var startDate = new Date(checkInOutValue.checkInTime);
    var endDate = new Date(checkInOutValue.checkOutTime);
    if (checkInOutValue.checkOutTime == null) {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: t("headerSearch.search.checkoutdateValidationMsg"),
      });
      return;
    }
    if (place === "") {
      setPlaceSearchError(t("home.search.PlaceValidationMsg"));

      return;
    }
    if (endDate.getTime() <= startDate.getTime()) {
      MySwal.fire({
        confirmButtonText: "OK",
        confirmButtonColor: "#296da9",
        text: t("home.search.dateValidationMsg"),
      });
      // errorToast(t("home.search.dateValidationMsg"));
      return;
    }

    // hotelWheatherApiRequest(place);
    navigate(
      {
        pathname: "listingbidnow",
        search: createSearchParams({
          search_type: "bid",
          city: place !== "" ? place : "",
          check_in_date: `${moment(
            checkInOutValue.checkInTime !== null
              ? new Date(checkInOutValue.checkInTime.toString())
              : "" + year + "-" + month + "-" + day + ""
          ).format("DD/MM/YYYY")}`,
          check_out_date: `${moment(
            checkInOutValue.checkInTime !== null
              ? new Date(checkInOutValue.checkOutTime.toString())
              : "" + year + "-" + month + "-" + nextday + ""
          ).format("DD/MM/YYYY")}`,
          adults: guestRoomData.adults,
          children: guestRoomData.child,
          rooms: guestRoomData.rooms,
        }).toString(),
      },
      {
        state: {
          search_type: "bid",
          city: place !== "" ? place : "",
          check_in_date: `${moment(
            checkInOutValue.checkInTime !== null
              ? new Date(checkInOutValue.checkInTime.toString())
              : "" + year + "-" + month + "-" + day + ""
          ).format("DD/MM/YYYY")}`,
          check_out_date: `${moment(
            checkInOutValue.checkInTime !== null
              ? new Date(checkInOutValue.checkOutTime.toString())
              : "" + year + "-" + month + "-" + nextday + ""
          ).format("DD/MM/YYYY")}`,
          adults: guestRoomData.adults,
          children: guestRoomData.child,
          rooms: guestRoomData.rooms,
        },
      }
    );
  };
  /* Search and Naviate By Date */

  /* Get Offers and Promotions API Request */
  useEffect(() => {
    getOfferPromotionsPartnersPreferenceRequest(languageToShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageToShow]);

  useEffect(() => {
    setPlace(selectPlaceDataHandleData?.place);
    setLongaddress(selectPlaceDataHandleData?.place);
  }, [selectPlaceDataHandleData]);
  /* Get Offers and Promotions API Request */

  /******** Preference Filter Should Be stop  **********/
  useEffect(() => {
    hotelToggleAddOrNot(false);
    getPerferenceDataList(null);
    hotelOfferremoveHandlerDataList(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /******** Preference Filter Should Be stop  **********/

  const hendleDatePickercheckInClose = () => {
    setCheckIn(false);
    setCheckoutTime(true);
  };

  /**
   * Sets an item in the sessionStorage with an expiration time.
   *
   * @param {string} key - The key to store the item under.
   * @param {any} value - The value to store.
   * @param {number} ttl - The time to live in milliseconds.
   * @return {void} This function does not return a value.
   */
  const setItemWithExpiry = (key, value, ttl) => {
    const now = new Date();

    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  };

  const getItemWithExpiry = (key) => {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  return (
    <>
      {/* banner start */}
      {/* For SEO */}
      <Helmet>
        <title>Wfrlee.com: Booking a Short Stay in Saudi Arabia</title>
        <meta
          name="description"
          content="Experience Pay As You Stay with Wfrlee: Flexible Pricing, Hourly Rates, and Short Stay Savings in Saudi Arabia. Book now for affordable hotel stays!"
        />
        <meta
          name="keywords"
          content="Pay as You Stay, Wfrlee hotel service, Hotel flexibility pricing, Short-stay hotel savings, Pay-per-hour hotels, Stay and pay concept, Saudi Arabia hotel deals, Hourly hotel rates, Affordable hotel stays, Flexible hotel pricing, Stay and save hotels, Riyadh pay-as-you-stay, Jeddah hourly hotels, Hotel booking flexibility, KSA short-stay options, Hotel room-by-hour, Stay for less, Hourly booking Wfrlee, Discounted hotel stays, Transparent hotel pricing, Hotel check-in check-out, Hotel flexibility in Saudi, Budget-friendly lodging, Wfriee Saudi accommodations, Saudi hotel booking revolution, Best hotel rates, Pay-as-you-go hotels, Convenient hotel stays, Affordable lodging options, Revolutionizing hotel stays, Save on short stays, Book hotel by the hour, Hourly hotel reservations, Flexi-stay accommodations, Wfrlee short stay, Hourly hotel rooms, Smart hotel bookings, Hotel stay innovation, Economical hotel choices, Hotel room flexibility, Booking short stays, Pay-per-use hotels, Convenient hotel options, Unique hotel experience, Short stay convenience, Wfree innovative stays, Best hotel deals"
        />
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      </Helmet>

      <div className="home-banner">
        <div className="container">
          {/* search hotel start */}
          <div className="row">
            <div className="col-lg-12">
              <div className="search-hotel">
                <Tabs defaultActiveKey="first">
                  <Tab eventKey="first" title={t("home.search.HourStay")}>
                    <div className="tab-container hourly-stay">
                      <form onSubmit={searchsubmit}>
                        <div className="form-content">
                          <div className="left">
                            <Form.Label
                              className={`${
                                longaddress === "" ? "add-width" : ""
                              }`}
                            >
                              {longaddress === ""
                                ? `${t("home.search.WHERE")}`
                                : null}
                            </Form.Label>
                            <Form.Group className="form-box location-drop">
                              <div>
                                <Suspense
                                  fallback={
                                    <div>{t("home.search.Loading")}</div>
                                  }
                                >
                                  <AutocompleteReact
                                    focusCheckinDate={() => {
                                      if (
                                        !getItemWithExpiry("@guide_checkin")
                                      ) {
                                        setIsOpen(true);
                                        setItemWithExpiry(
                                          "@guide_checkin",
                                          true,
                                          3600000
                                        );
                                      }
                                    }}
                                  />
                                </Suspense>
                              </div>
                            </Form.Group>
                            <Form.Group className="form-box checkin">
                              {!isMobile ? (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    className="datepickercheck-in"
                                    style={{ cursor: "pointer" }}
                                    label={`${t("home.search.CheckinLabel")}`}
                                    value={checkInOutValue.checkInTime}
                                    onChange={(newValue) => {
                                      setCheckInOutValue((checkInOutValue) => ({
                                        ...checkInOutValue,
                                        checkInTime: newValue,
                                      }));
                                    }}
                                    disablePast
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    onClose={() => setIsOpen(false)}
                                    KeyboardButtonProps={{
                                      onClick: (e) => {
                                        setIsOpen(true);
                                      },
                                    }}
                                    InputProps={{
                                      onClick: () => {
                                        setIsOpen(true);
                                      },
                                    }}
                                    open={isOpen}
                                    inputFormat="DD MMM YYYY"
                                    disableMaskedInput={true}
                                  />
                                </LocalizationProvider>
                              ) : (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileDatePicker
                                    label={`${t("home.search.CheckinLabel")}`}
                                    value={checkInOutValue.checkInTime}
                                    onChange={(newValue) => {
                                      setCheckInOutValue((checkInOutValue) => ({
                                        ...checkInOutValue,
                                        checkInTime: newValue,
                                      }));
                                    }}
                                    onClose={() => {
                                      setIsOpen(false);
                                      handleOpen();
                                    }}
                                    disablePast
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                    inputFormat="DD MMM YYYY"
                                    disableMaskedInput={true}
                                  />
                                </LocalizationProvider>
                              )}
                              {!isMobile ? (
                                <div
                                  className="pophours"
                                  ref={ref3}
                                  style={
                                    open
                                      ? { display: "block" }
                                      : { display: "none" }
                                  }
                                >
                                  <Box sx={style}>
                                    <Typography
                                      id="modal-modal-description"
                                      sx={{ mt: 2 }}
                                      className="hourlybook"
                                      onChange={handleHourscollect}
                                    >
                                      {t("home.search.BookFor")}
                                      <Form.Select aria-label="Default select example">
                                        <option>
                                          {/* Hours */}
                                          {t("home.search.Hours")}
                                        </option>

                                        <option value="3">
                                          {t("home.search.Hour3")}
                                        </option>
                                        <option value="6">
                                          {t("home.search.Hours6")}
                                        </option>
                                        <option value="12">
                                          {t("home.search.Hours12")}
                                        </option>
                                      </Form.Select>
                                    </Typography>
                                  </Box>
                                </div>
                              ) : (
                                <div
                                  className="pophours"
                                  style={
                                    open
                                      ? { display: "block" }
                                      : { display: "none" }
                                  }
                                >
                                  <Box sx={style}>
                                    <Typography
                                      id="modal-modal-description"
                                      sx={{ mt: 2 }}
                                      className="hourlybook"
                                      onChange={handleHourscollect}
                                    >
                                      {t("home.search.BookFor")}
                                      <Form.Select aria-label="Default select example">
                                        <option>
                                          {/* Hours */}
                                          {t("home.search.Hours")}
                                        </option>

                                        <option value="3">
                                          {t("home.search.Hour3")}
                                        </option>
                                        <option value="6">
                                          {t("home.search.Hours6")}
                                        </option>
                                        <option value="12">
                                          {t("home.search.Hours12")}
                                        </option>
                                      </Form.Select>
                                    </Typography>
                                  </Box>
                                </div>
                              )}

                              {getHours !== null && getHours > 0 ? (
                                <span className="hourlable">
                                  {getHours === 3
                                    ? t("home.search.Hour3")
                                    : getHours === 6
                                    ? t("home.search.Hours6")
                                    : t("home.search.Hours12")}
                                  {/* {getHours} Hours */}
                                </span>
                              ) : null}
                            </Form.Group>
                            <Form.Group className="form-box pop-form">
                              <Form.Control
                                type="text"
                                className="room"
                                placeholder={`${
                                  guestRoomData.adults + guestRoomData.child
                                } ${
                                  guestRoomData.adults + guestRoomData.child > 1
                                    ? `${t("home.search.Guests")}`
                                    : `${t("home.search.Guest")}`
                                }  / ${guestRoomData.rooms} ${
                                  guestRoomData.rooms > 1
                                    ? `${t("home.search.Rooms")}`
                                    : `${t("home.search.Room")}`
                                }`}
                                onClick={guestAndRoom}
                              />
                              <div
                                className="guestpop"
                                ref={ref}
                                style={
                                  guestRomsPopup
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <h3>
                                  {/* Add Guest(s) And Room(s) */}
                                  {t("home.search.AddGuest(s)andRoom(s)")}
                                </h3>
                                <ul>
                                  <li key={24}>
                                    <h4>
                                      {/* Room (s) */}
                                      {t("home.search.Room(s)")}
                                    </h4>
                                    <div className="counting">
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          handleDecrease("room");
                                        }}
                                      >
                                        <AiOutlineMinusCircle />
                                      </Button>
                                      {guestRoomData.rooms}
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          handleIncrease("room");
                                        }}
                                      >
                                        <BsPlusCircle />
                                      </Button>
                                    </div>
                                  </li>
                                  <li key={25}>
                                    <h4>
                                      {/* Adult (s) */}
                                      {t("home.search.Adult(s)")}
                                    </h4>
                                    <div className="counting">
                                      <Button
                                        onClick={() => {
                                          handleDecrease("adult");
                                        }}
                                      >
                                        <AiOutlineMinusCircle />
                                      </Button>
                                      {guestRoomData.adults}
                                      <Button
                                        onClick={() => {
                                          handleIncrease("adult");
                                        }}
                                      >
                                        <BsPlusCircle />
                                      </Button>
                                    </div>
                                  </li>
                                  <li key={26}>
                                    <h4>
                                      {/* Children (s)  */}
                                      {t("home.search.Children(s)")}
                                      <span>
                                        {/* Max 11 years */}
                                        {t("home.search.MaxYears11")}
                                      </span>
                                    </h4>
                                    <div className="counting">
                                      <Button
                                        onClick={() => {
                                          handleDecrease("children");
                                        }}
                                      >
                                        <AiOutlineMinusCircle />
                                      </Button>
                                      {guestRoomData.child}
                                      <Button
                                        onClick={() => {
                                          handleIncrease("children");
                                        }}
                                      >
                                        <BsPlusCircle />
                                      </Button>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="right">
                            <Button type="submit" value="submit">
                              {/* Search hotel */}
                              {t("home.search.searchBtn")}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Tab>
                  <Tab eventKey="second" title={t("home.search.BidNow")}>
                    <div className="tab-container bid-now">
                      <form onSubmit={bidsearchnow}>
                        <div className="form-content">
                          <div className="left">
                            <Form.Label
                              className={`${
                                longaddress === "" ? "add-width" : ""
                              }`}
                            >
                              {longaddress === ""
                                ? `${t("home.search.WHERE")}`
                                : null}
                            </Form.Label>
                            <Form.Group className="form-box location-drop">
                              <Suspense
                                fallback={<div>{t("home.search.Loading")}</div>}
                              >
                                <AutocompleteReact />
                              </Suspense>
                            </Form.Group>
                            <div className="bidchecking">
                              <Form.Group className="form-box ">
                                {!isMobile ? (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      ref={datePickerRef}
                                      className="datepickercheck-in check-in"
                                      label={`${t("home.search.CheckinLabel")}`}
                                      value={checkInOutValue.checkInTime}
                                      onChange={(newValue) => {
                                        // setValue();
                                        moment(new Date(newValue)).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        );
                                        setCheckInOutValue(
                                          (checkInOutValue) => ({
                                            ...checkInOutValue,
                                            checkInTime: newValue,
                                          })
                                        );
                                      }}
                                      disablePast
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      onClose={() => {
                                        hendleDatePickercheckInClose();
                                      }}
                                      KeyboardButtonProps={{
                                        onClick: (e) => {
                                          setCheckIn(true);
                                        },
                                      }}
                                      InputProps={{
                                        onClick: () => {
                                          setCheckIn(true);
                                        },
                                      }}
                                      open={checkIn}
                                      inputFormat="DD MMM YYYY"
                                      disableMaskedInput={true}
                                    />
                                  </LocalizationProvider>
                                ) : (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <MobileDatePicker
                                      className="datepickercheck-in check-in"
                                      label={`${t("home.search.CheckinLabel")}`}
                                      value={checkInOutValue.checkInTime}
                                      onChange={(newValue) => {
                                        // setValue();
                                        moment(new Date(newValue)).format(
                                          "YYYY-MM-DD HH:mm:ss"
                                        );
                                        setCheckInOutValue(
                                          (checkInOutValue) => ({
                                            ...checkInOutValue,
                                            checkInTime: newValue,
                                          })
                                        );
                                      }}
                                      disablePast
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      inputFormat="DD MMM YYYY"
                                      disableMaskedInput={true}
                                    />
                                  </LocalizationProvider>
                                )}
                              </Form.Group>
                              <Form.Group className="form-box check-out">
                                {!isMobile ? (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      className="datepickercheck-in"
                                      label={`${t(
                                        "home.search.CheckoutLabel"
                                      )}`}
                                      value={checkInOutValue.checkOutTime}
                                      onChange={(newValue) => {
                                        //setValue(moment(newValue).format("DD/MM/YYYY"));
                                        setCheckInOutValue(
                                          (checkInOutValue) => ({
                                            ...checkInOutValue,
                                            checkOutTime: newValue,
                                          })
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      onClose={() => setCheckoutTime(false)}
                                      KeyboardButtonProps={{
                                        onClick: (e) => {
                                          setCheckoutTime(true);
                                        },
                                      }}
                                      minDate={new Date(
                                        checkInOutValue.checkInTime
                                      ).setDate(
                                        new Date(
                                          checkInOutValue.checkInTime
                                        ).getDate() + 1
                                      )}
                                      disablePast
                                      InputProps={{
                                        onClick: () => {
                                          setCheckoutTime(true);
                                        },
                                      }}
                                      open={checkoutTime}
                                      inputFormat="DD MMM YYYY"
                                      disableMaskedInput={true}
                                    />
                                  </LocalizationProvider>
                                ) : (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <MobileDatePicker
                                      className="datepickercheck-in"
                                      label={`${t(
                                        "home.search.CheckoutLabel"
                                      )}`}
                                      value={checkInOutValue.checkOutTime}
                                      onChange={(newValue) => {
                                        //setValue(moment(newValue).format("DD/MM/YYYY"));
                                        setCheckInOutValue(
                                          (checkInOutValue) => ({
                                            ...checkInOutValue,
                                            checkOutTime: newValue,
                                          })
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} />
                                      )}
                                      disablePast
                                      minDate={new Date(
                                        checkInOutValue.checkInTime
                                      ).setDate(
                                        new Date(
                                          checkInOutValue.checkInTime
                                        ).getDate() + 1
                                      )}
                                      inputFormat="DD MMM YYYY"
                                      disableMaskedInput={true}
                                    />
                                  </LocalizationProvider>
                                )}
                              </Form.Group>
                            </div>
                            <Form.Group className="form-box pop-form">
                              <Form.Control
                                type="text"
                                className="room"
                                placeholder={`${
                                  guestRoomData.adults + guestRoomData.child
                                } ${
                                  guestRoomData.adults + guestRoomData.child > 1
                                    ? t("home.search.Guests")
                                    : t("home.search.Guest")
                                } / ${guestRoomData.rooms} ${
                                  guestRoomData.rooms > 1
                                    ? t("home.search.Rooms")
                                    : t("home.search.Room")
                                }`}
                                onClick={guestAndRoomsecond}
                              />
                              <div
                                className="guestpop"
                                ref={ref1}
                                style={
                                  guestRomsPopupsecond
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                              >
                                <h3>
                                  {/* Add Guest(s) And Room(s) */}
                                  {t("home.search.AddGuest(s)andRoom(s)")}
                                </h3>
                                <ul>
                                  <li>
                                    <h4>
                                      {/* Room (s) */}
                                      {t("home.search.Room(s)")}
                                    </h4>
                                    <div className="counting">
                                      <div>
                                        <Button
                                          onClick={() => {
                                            handleDecrease("room");
                                          }}
                                        >
                                          <AiOutlineMinusCircle />
                                        </Button>
                                      </div>
                                      {guestRoomData.rooms}
                                      <div>
                                        <Button
                                          onClick={() => {
                                            handleIncrease("room");
                                          }}
                                        >
                                          <BsPlusCircle />
                                        </Button>
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <h4>
                                      {/* Adult (s)  */}
                                      {t("home.search.Adult(s)")}
                                    </h4>
                                    <div className="counting">
                                      <Button
                                        onClick={() => {
                                          handleDecrease("adult");
                                        }}
                                      >
                                        <AiOutlineMinusCircle />
                                      </Button>
                                      {guestRoomData.adults}
                                      <Button
                                        onClick={() => {
                                          handleIncrease("adult");
                                        }}
                                      >
                                        <BsPlusCircle />
                                      </Button>
                                    </div>
                                  </li>
                                  <li>
                                    <h4>
                                      {/* Children (s)  */}
                                      {t("home.search.Children(s)")}

                                      <span>
                                        {/* Max 11 years */}
                                        {t("home.search.MaxYears11")}
                                      </span>
                                    </h4>
                                    <div className="counting">
                                      <Button
                                        onClick={() => {
                                          handleDecrease("children");
                                        }}
                                      >
                                        <AiOutlineMinusCircle />
                                      </Button>
                                      {guestRoomData.child}
                                      <Button
                                        onClick={() => {
                                          handleIncrease("children");
                                        }}
                                      >
                                        <BsPlusCircle />
                                      </Button>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </Form.Group>
                          </div>
                          <div className="right">
                            <button type="submit" value="submit">
                              {/* Search hotel */}
                              {t("home.search.searchBtn")}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Tab>
                </Tabs>
                <div className="errorformmessage errormessagesearch">
                  {placeSearchError}
                </div>
              </div>
            </div>
          </div>
          {/* search hotel start */}
        </div>
      </div>

      <div className="home-banner-below">
        <div className="container">
          {/* banner item start */}
          <div className="row">
            <div className="col-lg-12">
              <ul className="banner-item-list">
                <li>
                  <span className="icon">
                    <img
                      src={FlexibleStay}
                      width={84}
                      alt={t("home.bannerItemList.FlexibleStay.heading")}
                    />
                  </span>
                  <span className="title">
                    {/* Flexible Stay */}
                    {t("home.bannerItemList.FlexibleStay.heading")}
                  </span>
                  <p>
                    {/* feel free to choose your own<br></br>check-in, check-out
                    time */}
                    {t("home.bannerItemList.FlexibleStay.text1")}
                    <br></br>
                    {t("home.bannerItemList.FlexibleStay.text2")}
                  </p>
                </li>

                <li>
                  <span className="icon">
                    <img
                      src={Discounts}
                      width={84}
                      alt={t("home.bannerItemList.BestHotels.heading")}
                    />
                  </span>
                  <span className="title">
                    {/* Best Hotels */}
                    {t("home.bannerItemList.BestHotels.heading")}
                  </span>
                  <p>
                    {/* Check-in & Check-out of our<br></br>hotels at your
                    convenience */}
                    {t("home.bannerItemList.BestHotels.text1")}
                    <br></br>
                    {t("home.bannerItemList.BestHotels.text2")}
                  </p>
                </li>

                <li>
                  <span className="icon">
                    <img
                      src={BestHotels}
                      width={84}
                      alt={t("home.bannerItemList.Uptooff.heading")}
                    />
                  </span>
                  <span className="title">
                    {/* Up to -75% off */}
                    {t("home.bannerItemList.Uptooff.heading")}
                  </span>
                  <p>
                    {/* get discounts for your stay<br></br>your stay, our discounts */}
                    {t("home.bannerItemList.Uptooff.text1")}
                    <br></br>
                    {t("home.bannerItemList.Uptooff.text2")}
                  </p>
                </li>

                <li>
                  <span className="icon">
                    <img
                      src={BidYourPrice}
                      width={84}
                      alt={t("home.bannerItemList.BidyourOwnPrice.heading")}
                    />
                  </span>
                  <span className="title">
                    {/* Bid your Own Price */}
                    {t("home.bannerItemList.BidyourOwnPrice.heading")}
                  </span>
                  <p>
                    {/* Bid a price and win best<br></br>rates to stay with us. */}
                    {t("home.bannerItemList.BidyourOwnPrice.text1")}
                    <br></br>
                    {t("home.bannerItemList.BidyourOwnPrice.text2")}
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {/* banner item end */}
        </div>
      </div>

      {/* banner end */}

      {/* //////////offer and promotions carousel/////////// */}
      <div className="carousel_st ofer_mwrp">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              {offersPromotionsDestinations !== null ? (
                offersPromotionsDestinations.offers.length > 0 ? (
                  <>
                    <h2>
                      {/* Offers and Promotions */}
                      {t("home.OffersandPromotions.heading")}
                    </h2>

                    {offersPromotionsDestinations !== null ? (
                      offersPromotionsDestinations.offers.length > 0 ? (
                        offersPromotionsDestinations.offers.length > 3 ? (
                          <Slider {...offerPromotion}>
                            {offersPromotionsDestinations !== null ? (
                              offersPromotionsDestinations.offers.length > 0 ? (
                                offersPromotionsDestinations.offers.map(
                                  (offers, index) => {
                                    return (
                                      <div className="inner_mbox" key={index}>
                                        <div
                                          className="inner_offerbox"
                                          key={index}
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            hotelOfferHandlerDataList(
                                              offers._id
                                            );
                                            getPerferenceDataList(null);
                                            populartoleasting(
                                              place.toString() !== ""
                                                ? place.toString()
                                                : "Riyadh"
                                            );
                                          }}
                                        >
                                          <img src={offers.image} alt="" />
                                          <h4
                                            dangerouslySetInnerHTML={{
                                              __html: offers.name,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                t("home.Loading")
                              )
                            ) : (
                              <div className="inner_mbox">
                                <div className="inner_offerbox">
                                  <h4>{t("home.Loading")}</h4>
                                </div>
                              </div>
                            )}
                          </Slider>
                        ) : (
                          offersPromotionsDestinations.offers.map(
                            (offers, index) => {
                              return (
                                <div
                                  className="inner_mbox"
                                  key={index}
                                  style={{ display: "inline-block" }}
                                >
                                  <div
                                    className="inner_offerbox"
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      hotelOfferHandlerDataList(offers._id);
                                      getPerferenceDataList(null);
                                      populartoleasting("");
                                    }}
                                  >
                                    <img src={offers.image} alt="" />
                                    <h4
                                      dangerouslySetInnerHTML={{
                                        __html: offers.name,
                                      }}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )
                        )
                      ) : (
                        t("home.Loading")
                      )
                    ) : (
                      <div className="inner_mbox">
                        <div className="inner_offerbox">
                          <h4>{t("home.Loading")}</h4>
                        </div>
                      </div>
                    )}
                  </>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* //////////partner carousel/////////// */}
      <div className="carousel_st partner_mwrp">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2>
                {/* Our Partners */}
                {t("home.OurPartners.heading")}
              </h2>

              <Slider {...partner}>
                {offersPromotionsDestinations !== null ? (
                  offersPromotionsDestinations.partners.length > 0 ? (
                    offersPromotionsDestinations.partners.map(
                      (partners, index) => {
                        return (
                          <div className="partner_innerbox_mwrp" key={index}>
                            <div className="partner_innerbox">
                              <img src={partners.image} key={index} alt="" />
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="inner_mbox">
                      <div className="inner_offerbox">{/* <h4></h4> */}</div>
                    </div>
                  )
                ) : (
                  <div className="inner_mbox">
                    <div className="inner_offerbox">
                      <h4>{t("home.Loading")}</h4>
                    </div>
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* //////////Preference carousel/////////// */}
      <div className="preference_mwrap carousel_st">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1>
                {/* Select your preference */}
                {t("home.SelectYourPreference.heading")}
              </h1>
              <Slider {...preference}>
                {offersPromotionsDestinations !== null ? (
                  offersPromotionsDestinations.preferences.length > 0 ? (
                    offersPromotionsDestinations.preferences.map(
                      (value, index) => {
                        return (
                          <div className="pref_innerbox" key={index}>
                            <div className="pref_imgbox">
                              <img
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  getPerferenceDataList(
                                    `${value._id.toString()}`
                                  );
                                  hotelOfferHandlerDataList(null);
                                  populartoleasting(
                                    place.toString() !== ""
                                      ? place.toString()
                                      : "Riyadh"
                                  );
                                }}
                                src={value.image}
                                alt=""
                              />
                            </div>
                            <h5>{value.name}</h5>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="inner_mbox">
                      <div className="inner_offerbox">{/* <h4></h4> */}</div>
                    </div>
                  )
                ) : (
                  <div className="inner_mbox">
                    <div className="inner_offerbox">
                      <h4>{t("home.Loading")}</h4>
                    </div>
                  </div>
                )}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      {/* popular destinations start */}
      <div className="popular-destinations">
        <div className="container">
          <div className="row popular-destinations-header">
            <div className="col-md-7">
              <h2>
                {/* Popular Destinations */}
                {t("home.PopularDestinations.heading")}
              </h2>
            </div>
            <div className="col-md-5"></div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ul className="destinations-list">
                {destinationsData !== null ? (
                  destinationsData.length > 0 ? (
                    destinationsData.map((destinations, index) => {
                      return (
                        <li key={index}>
                          <div
                            className="destinations-box"
                            onClick={() => {
                              getPerferenceDataList(null);
                              hotelOfferHandlerDataList(null);
                              populartoleasting(
                                destinations.city_name_en.toString()
                              );
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <img
                              key={index}
                              src={destinations.image}
                              alt="popular destinations"
                            />
                          </div>
                          <div className="caption">
                            <div className="title">
                              {destinations.city_name}
                            </div>
                            <div className="price">{destinations.price}</div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    t("home.Loading")
                  )
                ) : (
                  <div className="inner_mbox">
                    <div className="inner_offerbox">
                      <h4>{t("home.Loading")}</h4>
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* popular destinations end */}

      {/* app wrapper start */}
      <div className="app-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 app-pic">
              <img src="img/iconMobileScreen.png" alt="app-screen" />
              <img src="img/iconMobilearobicScreen.png" alt="app-screen" />
            </div>
            <div className="col-lg-7 app-txt">
              <h2>
                {/* An App To Fill Your Needs */}
                {t("home.AnAppToFillYourNeeds.heading")}
              </h2>
              <p>
                {/* Get our iOS or Android App to get aseamless experience on hourly
                hotel booking. */}
                {t("home.AnAppToFillYourNeeds.text")}
              </p>
              <ul className="app-icon">
                <li>
                  <a
                    href="https://apps.apple.com/us/app/wfrlee-com/id1672569695"
                    target="blank"
                  >
                    <img src="img/apple-store.png" alt="apple-store" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.wfrlee&hl=en-IN&pli=1"
                    target="blank"
                  >
                    <img src="img/google-play.png" alt="google-play" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* app wrapper end */}

      {/* What Our Guests Say */}
      <div className="guests-review">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="reviewcontent">
                <h2>{t("home.WhatOurGuestsSay.heading")}</h2>
                <div className="totalstararea">
                  <div className="reviewstar">
                    {/* {console.log("selectRandomReview--->> ", selectRandomReview)} */}
                    {selectRandomReview !== null ? (
                      <Box>
                        <StyledRating
                          name="read-only"
                          defaultValue={Number(
                            selectRandomReview.average_rating
                          )}
                          icon={<BsFillStarFill fontSize="inherit" />}
                          emptyIcon={<BsStar fontSize="inherit" />}
                          readOnly
                        />
                      </Box>
                    ) : null}
                  </div>
                  <p>{t("home.WhatOurGuestsSay.text")}</p>
                  <Link to="/rating-and-review" className="gen-btn">
                    {t("home.WhatOurGuestsSay.SeeAllReviewsBtn")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {selectRandomReview !== null &&
              selectRandomReview.customer_review &&
              selectRandomReview.customer_review[0] ? (
                <div
                  data-aos="fade-left"
                  data-aos-offset="9"
                  data-aos-delay="20"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-anchor-placement="top-center"
                  className="reviewbox maincolor"
                >
                  <p>
                    {selectRandomReview.customer_review &&
                      selectRandomReview.customer_review[0].review}{" "}
                  </p>
                  <div className="reviewstar">
                    <Box>
                      <StyledRating
                        name="read-only"
                        defaultValue={
                          selectRandomReview.customer_review
                            ? Number(
                                selectRandomReview.customer_review[0].rating
                              )
                            : 0
                        }
                        icon={<BsFillStarFill fontSize="inherit" />}
                        emptyIcon={<BsStar fontSize="inherit" />}
                        readOnly
                      />
                    </Box>
                  </div>
                  <div className="authorname">
                    {selectRandomReview.customer_review[0].customer_name}
                  </div>
                </div>
              ) : null}
              {selectRandomReview != null &&
              selectRandomReview.customer_review &&
              selectRandomReview.customer_review[1] ? (
                <div
                  data-aos="fade-up"
                  data-aos-offset="9"
                  data-aos-delay="20"
                  data-aos-duration="500"
                  data-aos-easing="ease-in-out"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-anchor-placement="top-center"
                  className="reviewbox bluecolor"
                >
                  <p>{selectRandomReview.customer_review[1].review} </p>
                  <div className="reviewstar">
                    <Box>
                      <StyledRating
                        name="read-only"
                        defaultValue={Number(
                          selectRandomReview.customer_review[1].rating
                        )}
                        icon={<BsFillStarFill fontSize="inherit" />}
                        emptyIcon={<BsStar fontSize="inherit" />}
                        readOnly
                      />
                    </Box>
                  </div>
                  <div className="authorname">
                    {selectRandomReview.customer_review[1].customer_name}
                  </div>
                </div>
              ) : null}
            </div>

            <div className="col-md-4">
              {selectRandomReview != null &&
              selectRandomReview.customer_review &&
              selectRandomReview.customer_review[2] ? (
                <div
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  className="reviewbox bluecolor"
                >
                  <p>{selectRandomReview.customer_review[2].review} </p>
                  <div className="reviewstar">
                    <Box>
                      <StyledRating
                        name="read-only"
                        defaultValue={Number(
                          selectRandomReview.customer_review[2].rating
                        )}
                        icon={<BsFillStarFill fontSize="inherit" />}
                        emptyIcon={<BsStar fontSize="inherit" />}
                        readOnly
                      />
                    </Box>
                  </div>
                  <div className="authorname">
                    {selectRandomReview.customer_review[2].customer_name}
                  </div>
                </div>
              ) : null}
              {selectRandomReview != null &&
              selectRandomReview.customer_review &&
              selectRandomReview.customer_review[3] ? (
                <div
                  data-aos="slide-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  className="reviewbox maincolor"
                >
                  <p>{selectRandomReview.customer_review[3].review} </p>
                  <div className="reviewstar">
                    <Box>
                      <StyledRating
                        name="read-only"
                        defaultValue={Number(
                          selectRandomReview.customer_review[3].rating
                        )}
                        icon={<BsFillStarFill fontSize="inherit" />}
                        emptyIcon={<BsStar fontSize="inherit" />}
                        readOnly
                      />
                    </Box>
                  </div>
                  <div className="authorname">
                    {selectRandomReview.customer_review[3].customer_name}
                  </div>
                </div>
              ) : null}
              {selectRandomReview != null &&
              selectRandomReview.customer_review &&
              selectRandomReview.customer_review[4] ? (
                <div
                  data-aos="fade-left"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                  className="reviewbox bluecolor"
                >
                  <p>{selectRandomReview.customer_review[4].review}</p>
                  <div className="reviewstar">
                    <Box>
                      <StyledRating
                        name="read-only"
                        defaultValue={Number(
                          selectRandomReview.customer_review[4].rating
                        )}
                        icon={<BsFillStarFill fontSize="inherit" />}
                        emptyIcon={<BsStar fontSize="inherit" />}
                        readOnly
                      />
                    </Box>
                  </div>
                  <div className="authorname">
                    {selectRandomReview.customer_review[4].customer_name}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {/* //////////Get Special Section/////////// */}

      <SpecialSection />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  languageToShow: selectlanguageToShow,
  offersPromotionsDestinations: selectofferspromotionsdestinationData,
  selectofferLoading: selectofferLoading,
  selectPlaceDataHandleData: selectPlaceDataHandleData,
  selectRandomReview: selectRandomReview,
});
const mapDispatchToProps = (dispatch) => ({
  getOfferPromotionsPartnersPreferenceRequest: (data) =>
    dispatch(getOfferPromotionsPartnersPreferenceRequest(data)),
  getPerferenceDataList: (data) => dispatch(getPerferenceDataList(data)),
  hotelOfferHandlerDataList: (data) =>
    dispatch(hotelOfferHandlerDataList(data)),
  hotelPlaceDataHandle: (data) => dispatch(hotelPlaceDataHandle(data)),
  hotelToggleAddOrNot: (data) => dispatch(hotelToggleAddOrNot(data)),
  getHomePageRandomReviewRequest: (data) =>
    dispatch(getHomePageRandomReviewRequest(data)),
  hotelOfferremoveHandlerDataList: (data) =>
    dispatch(hotelOfferremoveHandlerDataList(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
