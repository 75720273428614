import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import validator from "validator";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectlanguageToShow } from "./../../redux/language/language.selectors";
import { selectcurrencyToShow } from "./../../redux/currency/currency.selectors";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  selectUserLoginData,
  selectValidateUserEmailData,
  selectSignUpMessage,
  selectCurrentUser,
} from "./../../redux/user/user.selectors";
import {
  selectHourlyCheckoutDetails,
  selectHotelBooking,
  selectApplyHotelOffers,
  selectFavouriteHotelSearchData,
  selectSaveGuestUserCheckoutData,
  selectApplyHotelOffersFailedData,
  selectHotelBookingLoading,
  selectApplyHotelOffersLoading,
} from "../../redux/hotels/hotel.selectors";
import {
  getHourlyCheckoutDetailsRequest,
  hotelBookingRequest,
  applyHotelOffersRequest,
  stateClearAfterTask,
  saveGuestUserCheckoutDataRequest,
  hotelWheatherApiRequest,
} from "../../redux/hotels/hotel.actions";
import moment from "moment";
import { errorToast } from "../../utils/toastHelper";
import {
  validateUserEmailRequest,
  validateEmailStateClearAfterTask,
} from "../../redux/user/user.actions";
import { Link } from "react-router-dom";
import {
  useSearchParams,
  useLocation,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import CheckoutUserForm from "../../components/checkout/CheckoutUserForm";
import CheckoutCardDetailsForm from "../../components/checkout/CheckoutCardDetailsForm";
import CheckoutHotelDetails from "../../components/checkout/CheckoutHotelDetails";
import {
  CardNumberFormatValidator,
  CardExpiryFormatValidator,
  MobileNumberValidator,
  EmailValidator,
} from "../../constants/InputValidator";
import ConfirmDialog from "../../components/common/dialogs/ConfirmDialog";
import BookingDetailsDialog from "../../components/checkout/BookingDetailsDialog";
import LoginModal from "../../components/checkout/LoginModal";
import BookingConditionModal from "../../components/checkout/BookingCondition";
import LoginPage from "../../pages/Login/Login";
import BookingConfirmationDetailsModal from "../../components/checkout/BookingConfirmationDetailsModal";
import Spinner from "react-bootstrap/Spinner";
import { useTranslation } from "react-i18next";
import ViewPromoCodeList from "./../../components/checkout/viewPromocodeList";
import UnionPay from "./../../assets/images/union.png";
import applepay from "./../../assets/images/pay.png";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const HourlyGuestUser = ({
  hourlyCheckoutDetails,
  getHourlyCheckoutDetailsRequest,
  hotelBookingRequest,
  hotelBooking,
  applyHotelOffersRequest,
  applyHotelOffers,
  languageToShow,
  userAuthData,
  stateClearAfterTask,
  saveGuestUserCheckoutDataRequest,
  saveGuestUserCheckoutData,
  currencyToShow,
  searchsavedData,
  applyHotelOffersFailedData,
  hotelWheatherApiRequest,
  validateUserEmailRequest,
  validateEmailStateClearAfterTask,
  validateUserEmail,
  hotelBookingLoading,
  applyHotelOffersLoading,
  signUpmessage,
  currentUser,
}) => {
  const MySwal = withReactContent(Swal);
  const [t] = useTranslation("common");
  const location = useLocation();

  const firstElementRef = useRef(null);
  const secondElementRef = useRef(null);

  // Event handler for the first element
  const handleFirstElementClick = () => {
    // Do something when the first element is clicked
    console.log('First element clicked');

    
    secondElementRef.current.click();
  };

  // Event handler for the second element
  const handleSecondElementClick = () => {
    // Do something when the second element is clicked
    console.log('Second element clicked');
  };

 
  let {
    search_type,
    hotel_id,
    room_type_id,
    check_in_date,
    check_out_date,
    adults,
    rooms,
    children,
    hour,
    book_for,
    city,
  } = location && location.state;
  
  const navigate = useNavigate();
  var [showLoginPopup, setShowLoginPopup] = useState(false);
  const [bookingConditionShow,setBookingConditionShow] = useState(false);
  const [paymentModeValue, setPaymentModeValue] = React.useState("cash");
  const [walletBal, setWalletBal] = React.useState("");
  const [walletBalErr, setWalletBalErr] = React.useState("");
  const [promoCode, setPromoCode] = React.useState("");
  const [promoCodeErr, setPromoCodeErr] = React.useState("");
  const [selectedCheckInTime, setSelectedCheckInTime] = React.useState("");
  const [videPromoList, setViewPromoList] = React.useState(false);
  const [pointsApplied, setPointsApplied] = React.useState(false);
  const [promoCodeApplied, setPromoCodeApplied] = React.useState(false);
  const [totalAmount, setTotalAmount] = React.useState("");
  const [paymentButton, setPaymentButton] = React.useState();
  const [paymentButtonActive, setPaymentButtonActive] = React.useState(false);
  const [payAtHotelSarAgree, setpPayAtHotelSarAgree] = React.useState(false);
  // const [selectedCardType, setSelectedCardType] = React.useState("");
  // const [selectedCardTypeErr, setSelectedCardTypeErr] = React.useState("");

  const translatedMessageObj = {
    MobileNumberValidatorMsgObj: {
      pleaseEnterYourMobileNumber: t(
        "validatorMsg.pleaseEnterYourMobileNumber"
      ),
      PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
      Atleast10DigitsRequired: t("validatorMsg.Atleast10DigitsRequired"),
      MobileNumberIsInvalid: t("validatorMsg.MobileNumberIsInvalid"),
    },
    EmailValidatorMsgObj: {
      PleaseEnterYourEmail: t("validatorMsg.PleaseEnterYourEmail"),
      PleaseEnteraValidEmailAddress: t(
        "validatorMsg.PleaseEnteraValidEmailAddress"
      ),
    },
    PleaseEnteronlyNumber: t("validatorMsg.PleaseEnteronlyNumber"),
  };
  const [disabledBtn, setDisabledBtn] = React.useState(true);

  const [userData, setUserData] = React.useState({
    firstname:
      hourlyCheckoutDetails?.data?.user_details?.first_name != null
        ? hourlyCheckoutDetails?.data?.user_details?.first_name
        : "",
    lastname:
      hourlyCheckoutDetails?.data?.user_details?.last_name != null
        ? hourlyCheckoutDetails?.data?.user_details?.last_name
        : "",
    email:
      hourlyCheckoutDetails?.data?.user_details?.email != null
        ? hourlyCheckoutDetails?.data?.user_details?.email
        : "",
    confirmEmail:
      hourlyCheckoutDetails?.data?.user_details?.email != null
        ? hourlyCheckoutDetails?.data?.user_details?.email
        : "",
    mobileNo:
      hourlyCheckoutDetails?.data?.user_details?.contact_n != null
        ? hourlyCheckoutDetails?.data?.user_details?.contact_no
        : "",
 
    expecedTimeArrival: "",
  });

  const [userDataError, setuserDataError] = React.useState({
    firstnameErr: "",
    emailErr: "",
    confirmEmailErr: "",
    mobileNoErr: "",
  });

  const [cardData, setCardData] = React.useState({
    cardnumber:
      saveGuestUserCheckoutData?.card_details?.cardnumber != null
        ? saveGuestUserCheckoutData?.card_details?.cardnumber
        : "",
    nameoncard:
      saveGuestUserCheckoutData?.card_details?.nameoncard != null
        ? saveGuestUserCheckoutData?.card_details?.nameoncard
        : "",
    expirydate:
      saveGuestUserCheckoutData?.card_details?.expirydate != null
        ? saveGuestUserCheckoutData?.card_details?.expirydate
        : "",
    securitycode:
      saveGuestUserCheckoutData?.card_details?.securitycode != null
        ? saveGuestUserCheckoutData?.card_details?.securitycode
        : "",
    selectedCardType:
      saveGuestUserCheckoutData?.card_details?.selectedCardType != null
        ? saveGuestUserCheckoutData?.card_details?.selectedCardType
        : "",
    zipcode:
      saveGuestUserCheckoutData?.card_details?.zipcode != null
        ? saveGuestUserCheckoutData?.card_details?.zipcode
        : "",
    termsandConditions:
      saveGuestUserCheckoutData?.card_details?.termsandConditions != null
        ? saveGuestUserCheckoutData?.card_details?.termsandConditions
        : false,
    payAtHotelConditions:
        saveGuestUserCheckoutData?.card_details?.payAtHotelConditions != null
          ? saveGuestUserCheckoutData?.card_details?.payAtHotelConditions
          : false,
  });

  const [cardDataError, setCardDataError] = React.useState({
    cardnumberErr: "",
    nameoncardErr: "",
    expirydateErr: "",
    securitycodeErr: "",
    selectedCardTypeErr: "",
    zipcodeErr: "",
    termsandConditionsErr: "",
    payAtHotelConditionsErr: "",
    sarTermsError: ""
  });

  const [countryCode, setCountryCode] = React.useState(
    hourlyCheckoutDetails?.data?.user_details?.country_code != null
      ? hourlyCheckoutDetails?.data?.user_details?.country_code
      : "+966"
  );
  const [countryCodeErr, setCountryCodeErr] = React.useState("");
  const firstnameRef = React.useRef();
  const emailRef = React.useRef();
  const confirmEmailRef = React.useRef();
  const mobileNoRef = React.useRef();
  const countryCodeRef = React.useRef();
  const expectedTimeArrRef = React.useRef();
  const cardnumberRef = React.useRef();
  const nameoncardRef = React.useRef();
  const expirydateRef = React.useRef();
  const securitycodeRef = React.useRef();
  const selectedCardTypeRef = React.useRef();
  const zipcodeRef = React.useRef();
  const [expDate, setExpDate] = React.useState();

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showBookingDetailsModal, setShowBookingDetailsModal] = useState(false);
  const [showBookingConfirmModal, setShowBookingConfirmModal] = useState(false);

  const specificSectionRef = useRef();
  const [scrollToSection, setScrollToSection] = useState(false);

  React.useEffect(() => {
    if (scrollToSection && specificSectionRef.current) {
      console.log("Call Here -->> ");
      specificSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrollToSection(false);
    }
  }, [scrollToSection]);

  const handleLinkClick = () => {
    setScrollToSection(true);
  };

  // For handle payment summery click
  const contentRef = useRef();
  useEffect(() => {
    const handleClick = (event) => {
      // Check if the clicked element has a specific class
      if (event.target.classList.contains('payment_summary')) {
        // Your click event logic here
        console.log('Element with class "payment_summary" clicked!');
        setScrollToSection(true);
        // specificSectionRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Attach click event to the parent element using event delegation
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('click', handleClick);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (contentElement) {
        contentElement.removeEventListener('click', handleClick);
      }
    };
  }, []);

  var collecSlider = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    validateEmailStateClearAfterTask();
    setuserDataError({
      ...userDataError,
      emailErr: "",
    });
  }, [])
  /*** Set Default User Details  ***/
  React.useEffect(() => {
    setUserData(
      hourlyCheckoutDetails != null
        ? {
          firstname:
            hourlyCheckoutDetails?.data?.user_details?.first_name != null
              ? hourlyCheckoutDetails?.data?.user_details?.first_name
              : "",
          lastname:
            hourlyCheckoutDetails?.data?.user_details?.last_name != null
              ? hourlyCheckoutDetails?.data?.user_details?.last_name
              : "",
          email:
            hourlyCheckoutDetails?.data?.user_details?.email != null
              ? hourlyCheckoutDetails?.data?.user_details?.email
              : "",
          confirmEmail:
            hourlyCheckoutDetails?.data?.user_details?.email != null
              ? hourlyCheckoutDetails?.data?.user_details?.email
              : "",
          mobileNo:
            hourlyCheckoutDetails?.data?.user_details?.contact_no != null
              ? hourlyCheckoutDetails?.data?.user_details?.contact_no
              : "",
          expecedTimeArrival: "",
        }
        : {
          firstname: "",
          lastname: "",
          email: "",
          confirmEmail: "",
          mobileNo: "",
          expecedTimeArrival: "",
        }
    );
    setCountryCode(
      hourlyCheckoutDetails != null
        ? hourlyCheckoutDetails?.data?.user_details?.country_code != null
          ? hourlyCheckoutDetails?.data?.user_details?.country_code
          : ""
        : ""
    );
    // cleanup function which will be invoked on component unmount
    return () => {
      setUserData({
        firstname: hourlyCheckoutDetails?.data?.user_details?.first_name,
        lastname: hourlyCheckoutDetails?.data?.user_details?.last_name,
        email: hourlyCheckoutDetails?.data?.user_details?.email,
        confirmEmail: hourlyCheckoutDetails?.data?.user_details?.email,
        mobileNo: hourlyCheckoutDetails?.data?.user_details?.contact_no,
        expecedTimeArrival: "",
      });
      setCountryCode(hourlyCheckoutDetails?.data?.user_details?.country_code);
    };
  }, [hourlyCheckoutDetails]);

  /***** Set default Card details for guest user login ** */
  React.useEffect(() => {
    setCardData(
      saveGuestUserCheckoutData != null
        ? {
          cardnumber:
            saveGuestUserCheckoutData?.card_details?.cardnumber != null
              ? saveGuestUserCheckoutData?.card_details?.cardnumber
              : "",
          nameoncard:
            saveGuestUserCheckoutData?.card_details?.nameoncard != null
              ? saveGuestUserCheckoutData?.card_details?.nameoncard
              : "",
          expirydate:
            saveGuestUserCheckoutData?.card_details?.expirydate != null
              ? saveGuestUserCheckoutData?.card_details?.expirydate
              : "",
          securitycode:
            saveGuestUserCheckoutData?.card_details?.securitycode != null
              ? saveGuestUserCheckoutData?.card_details?.securitycode
              : "",
          selectedCardType:
            saveGuestUserCheckoutData?.card_details?.selectedCardType != null
              ? saveGuestUserCheckoutData?.card_details?.selectedCardType
              : "",
          zipcode:
            saveGuestUserCheckoutData?.card_details?.zipcode != null
              ? saveGuestUserCheckoutData?.card_details?.zipcode
              : "",
          termsandConditions:
            saveGuestUserCheckoutData?.card_details?.termsandConditions !=
              null
              ? saveGuestUserCheckoutData?.card_details?.termsandConditions
              : false,
          payAtHotelConditions:
            saveGuestUserCheckoutData?.card_details?.payAtHotelConditions !=
            null
            ? saveGuestUserCheckoutData?.card_details?.payAtHotelConditions
            : false,
        }
        : {
          cardnumber: "",
          nameoncard: "",
          expirydate: "",
          securitycode: "",
          selectedCardType: "",
          zipcode: "",
          termsandConditions: false,
          payAtHotelConditions: false,
        }
    );

    // cleanup function which will be invoked on component unmount
    return () => {
      setCardData({
        cardnumber: saveGuestUserCheckoutData?.card_details?.cardnumber,
        nameoncard: saveGuestUserCheckoutData?.card_details?.nameoncard,
        expirydate: saveGuestUserCheckoutData?.card_details?.expirydate,
        securitycode: saveGuestUserCheckoutData?.card_details?.securitycode,
        selectedCardType:
          saveGuestUserCheckoutData?.card_details?.selectedCardType,
        zipcode: saveGuestUserCheckoutData?.card_details?.zipcode,
        termsandConditions:
          saveGuestUserCheckoutData?.card_details?.termsandCondition,
        payAtHotelConditions:
          saveGuestUserCheckoutData?.card_details?.payAtHotelConditions,
      });
    };
  }, []);


  /*** Fetching Checkout Details Hourly Wise   ***/
  React.useEffect(() => {
    const postData = {
      hotel_id: hotel_id,
      room_type_id: room_type_id,
      // slot_id: slot_id,
      hour: hour,
      check_in_date: check_in_date,
      check_out_date: check_in_date,
      adults: adults,
      children: children,
      no_of_rooms: rooms,
    };

    const data = {
      postData,
      languageToShow: languageToShow,
      token: userAuthData != null ? userAuthData.token : "",
    };
    getHourlyCheckoutDetailsRequest(data);
    window.scrollTo(0, 0);
  }, [languageToShow]);

  /*** Fetching Checkout Guest User details   ***/
  React.useEffect(() => {
    const data = {
      request_data: hourlyCheckoutDetails?.data?.request_data,
      user_details:
        hourlyCheckoutDetails != null
          ? Object.entries(hourlyCheckoutDetails?.data?.user_details).length > 0
            ? hourlyCheckoutDetails?.data?.user_details
            : userData
          : "",
      card_details: search_type === "hour" ? cardData : null,
      pay_type: paymentModeValue,
      promo_code: promoCode,
      bid_now_amount: 0,
      // slot_id: slot_id,
      hour: hour,
      book_for: book_for != null ? book_for : "",
      city: city != null ? city : "",
    };
    saveGuestUserCheckoutDataRequest(data);
    // hotelWheatherApiRequest(city);
  }, [userData, cardData, promoCode, paymentModeValue]);

  React.useEffect(() => { }, [
    hourlyCheckoutDetails,
    applyHotelOffers,
    validateUserEmail,
  ]);

  const handleCloseLoginPopup = () => {
    setShowLoginPopup(false);
  };

  const handleCloseBookingConditionPopup = () => {
    setBookingConditionShow(false);
  };
  const handleShowBookingConditionPopup = () => setBookingConditionShow(true);
  const handleShowLoginPopup = () => setShowLoginPopup(true);

  React.useEffect(() => {
    if (userAuthData != null) {
      const postData = {
        hotel_id: hotel_id,
        room_type_id: room_type_id,
        // slot_id: slot_id,
        hour: hour,
        check_in_date: check_in_date,
        check_out_date: check_in_date,
        adults: adults,
        children: children,
        no_of_rooms: rooms,
      };

      const data = {
        postData,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
      };
      getHourlyCheckoutDetailsRequest(data);
    }
  }, [showLoginPopup, languageToShow]);

  React.useEffect(() => {
    if (
      Object.values(userData).every((x) => x !== null || x !== "") &&
      userAuthData != null
    ) {
      setuserDataError({
        firstnameErr: "",
        emailErr: "",
        confirmEmailErr: "",
        mobileNoErr: "",
        expecedTimeArrivalErr: "",
      });
    }
  }, [userAuthData]);

  /*** Country Code Selection   ***/
  const handleCountryCodeChange = (value, data, event, formattedValue) => {
    if (data.dialCode == "") {
      setCountryCode("");
    } else {
      setCountryCode(formattedValue);
      // setCountryCode("");
      setCountryCodeErr("");
    }
  };

  /*** Card Type Selection   ***/
  const handleChangeCardType = (e) => {
    if (e.target.name == "selectedCardType") {
      setCardData({
        ...cardData,
        selectedCardType: e.target.value,
      });
      setCardDataError({
        ...cardDataError,
        selectedCardTypeErr: "",
      });
    } else {
      setCardData({
        ...cardData,
        selectedCardType: "",
      });
    }
  };

  /***  Cardnumber change handler  ***/
  const handleChangeCardNumber = (e) => {
    if (e.target.name == "cardnumber") {
      const cardNumberformat = CardNumberFormatValidator(e.target.value);

      setCardData({
        ...cardData,
        cardnumber: cardNumberformat,
      });
      setCardDataError({
        ...cardDataError,
        cardnumberErr: "",
      });
    } else {
      setCardData({
        ...cardData,
        cardnumber: "",
      });
      // if (!validator.isNumeric(e.target.value)) {
      //   setCardDataError({
      //     ...cardDataError,
      //     cardnumberErr: "Please Enter Only number",
      //   });
      // }
    }
  };

  

  const handleChangeTimePicker = (value) => {
    setUserData({
      ...userData,
      expecedTimeArrival: value.target.value,
    });
  };

  /*** Card Expiry Date handler  ***/
  const handleChangeExpiryDate = (e) => {
    if (e.target.name == "expirydate") {
      const expiryNumberFormat = CardExpiryFormatValidator(e.target.value);

      setCardData({
        ...cardData,
        expirydate: expiryNumberFormat,
      });
      setCardDataError({
        ...cardDataError,
        expirydateErr: "",
      });
    } else {
      setCardData({
        ...cardData,
        expirydate: "",
      });
    }
  };

  /*** Personal Details Handler  ***/
  const handlePersonalDetailsChange = (e) => {
    if (e.target.name == "firstname") {
      if (e.target.value === "") {
        setuserDataError({
          ...userDataError,
          firstnameErr: t(
            "checkoutPage.validationMsg.PleaseEnterYourFirstName"
          ),
        });
      } else {
        setuserDataError({
          ...userDataError,
          firstnameErr: "",
        });
      }
    } else if (e.target.name == "email") {
      const emailError = EmailValidator(
        e.target.value,
        translatedMessageObj.EmailValidatorMsgObj
      );
      if (emailError) {
        setuserDataError({
          ...userDataError,
          emailErr: emailError,
        });
      } else {
        setuserDataError({
          ...userDataError,
          emailErr: "",
        });
      }
    } else if (e.target.name == "confirmEmail") {
      setuserDataError({
        ...userDataError,
        confirmEmailErr: "",
      });
    } else {
      if (e.target.name == "mobileNo") {
        const isNumberError = MobileNumberValidator(
          e.target.value,
          translatedMessageObj.MobileNumberValidatorMsgObj
        );
        if (isNumberError) {
          setuserDataError({
            ...userDataError,
            mobileNoErr: isNumberError,
          });
        } else {
          setuserDataError({
            ...userDataError,
            mobileNoErr: "",
          });
        }
      }
    }
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  /*** Terms and Policy Handler  ***/
  const handletermspolicy = (e) => {
    if (e.target.checked) {
      setCardData({
        ...cardData,
        termsandConditions: true,
      });
      setCardDataError({
        ...cardDataError,
        termsandConditionsErr: "",
      });
    } else {
      setCardData({
        ...cardData,
        termsandConditions: false,
      });
    }
  };

  /*** Pay At Hotel Conditions Handler  ***/
  const handlePayAtHotelConditions = (e) => {
    if (e.target.checked) {
      setCardData({
        ...cardData,
        payAtHotelConditions: true,
      });
      setCardDataError({
        ...cardDataError,
        payAtHotelConditionsErr: "",
      });
    } else {
      setCardData({
        ...cardData,
        payAtHotelConditions: false,
      });
    }
  };

  /*** SAR Terms Handler  ***/
  const handlePayAtHotelTerms = (e) => {
    if (e.target.checked) {
      setpPayAtHotelSarAgree(true);
      setCardDataError({
        ...cardDataError,
        sarTermsError: "",
      });
    } else {
      setpPayAtHotelSarAgree(false);
    }
  }


  /*** Card Details Handler  ***/
  const handleCardDetailsChange = (e) => {
    if (e.target.name == "nameoncard") {
      setCardDataError({
        ...cardDataError,
        nameoncardErr: "",
      });
      
    } else if (e.target.name == "securitycode") {
      setCardDataError({
        ...cardDataError,
        securitycodeErr: "",
      });
      if (!validator.isNumeric(e.target.value)) {
        setCardDataError({
          ...cardDataError,
          securitycodeErr: t(
            "checkoutPage.validationMsg.PleaseEnteronlyNumber"
          ),
        });
      }
    } else {
      // if (e.target.name == "zipcode") {
      setCardDataError({
        ...cardDataError,
        zipcodeErr: "",
      });
      if (!validator.isNumeric(e.target.value)) {
        setCardDataError({
          ...cardDataError,
          zipcodeErr: t("checkoutPage.validationMsg.PleaseEnteronlyNumber"),
        });
      }
      
    }

    setCardData({
      ...cardData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckExistingUser = React.useCallback(
    (e) => {
      if (userAuthData == null) {
        if (e.target.value !== "") {
          const data = {
            email: e.target.value,
            languageToShow: languageToShow,
          };
          validateUserEmailRequest(data);
        }
      }
    },
    [languageToShow]
  );

  React.useEffect(() => {
    if (userAuthData == null && validateUserEmail != null) {
      if (validateUserEmail?.data?.is_email_exist) {
        setuserDataError({
          ...userDataError,
          emailErr: t(
            "checkoutPage.validationMsg.EmailAlreadyEexistsPleaseLogin"
          ),
        });
      } else {
        setuserDataError({
          ...userDataError,
          emailErr: "",
        });
      }
    }
  }, [languageToShow, validateUserEmail, userAuthData]);




  /*** Apply Wallet Balance Handler  ***/
  const applyWalletBal = (event) => {
    event.preventDefault();
    if (walletBal.length != 0) {
      setPointsApplied(!pointsApplied);

    }

    if (
      walletBal == "" &&
      hourlyCheckoutDetails?.data?.user_details?.wallet_balance_points > 0
    ) {
      setWalletBalErr(
        t("checkoutPage.validationMsg.PleaseEnterYourWalletBalance")
      );
    } else {
      var postData = {
        room_type_id: hourlyCheckoutDetails?.data?.request_data?.room_type_id,
        // slot_id: "",
        hour:
          hourlyCheckoutDetails?.data?.booking_details
            ?.total_length_of_stay_hours,
        no_of_rooms: hourlyCheckoutDetails?.data?.request_data?.no_of_rooms,

        no_of_points: walletBal,
        promo_code:
          promoCode != "" && applyHotelOffersFailedData?.code != 3014
            ? promoCode
            : "",
        bid_now_amount: 0,
        check_in_date: hourlyCheckoutDetails?.data?.request_data?.check_in_date,
        check_out_date:
          hourlyCheckoutDetails?.data?.request_data?.check_in_date,

      
      };

      if (pointsApplied) {
        delete postData.no_of_points;
        postData.remove_request = "no_of_points";
      }
      const data = {
        postData,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
      };

      

      applyHotelOffersRequest(data);
      if (pointsApplied) {
        setWalletBal("");
      } else {
        setWalletBal(walletBal);
      }

    }
  };

  useEffect(() => {
    if (walletBal.length == 0) {
      setWalletBalErr("")
    }
  }, [walletBal]);

  /*** Apply Promo Code Handler  ***/
  const applyPromoCode = (event) => {
    event.preventDefault();
    setPromoCodeApplied(!promoCodeApplied);
    if (promoCode == "") {
      setPromoCodeErr(t("checkoutPage.validationMsg.PleaseEnterYourPromoCode"));
    } else {
      setPromoCodeErr("");

      const postData = {
        room_type_id: hourlyCheckoutDetails?.data?.request_data?.room_type_id,
        // slot_id: hourlyCheckoutDetails?.data?.booking_details?.slot_id,
        hour:
          hourlyCheckoutDetails?.data?.booking_details
            ?.total_length_of_stay_hours,
        no_of_rooms: hourlyCheckoutDetails?.data?.request_data?.no_of_rooms,
        no_of_points:
          walletBal != "" &&
            hourlyCheckoutDetails?.data?.user_details?.wallet_balance_points > 0
            ? walletBal
            : 0,
        promo_code: promoCode,
        bid_now_amount: 0,
        check_in_date: hourlyCheckoutDetails?.data?.request_data?.check_in_date,
        check_out_date:
          hourlyCheckoutDetails?.data?.request_data?.check_in_date,
        email: saveGuestUserCheckoutData?.user_details?.email
      };
      if (promoCodeApplied) {
        delete postData.promo_code;
        postData.remove_request = "promo_code"
      }
      const data = {
        postData,
        languageToShow: languageToShow,
        token: userAuthData != null ? userAuthData.token : "",
      };

      applyHotelOffersRequest(data);
      if (promoCodeApplied) {
        setPromoCode("");
      } else {
        setPromoCode(promoCode);
      }

      // setPromoCode(promoCode);
    }
  };

  /*** View Promo Code Handler  ***/
  const viewPromoCode = () => {
    setViewPromoList(true);
  };
  const promoCodeListHide = () => {
    setViewPromoList(false);
  };

  /*** Payment Type Handler  ***/
  const handlePaymentMode = (event) => {
    setPaymentModeValue(event.target.value);
  };

  var isEmailExist =
    validateUserEmail != null && userAuthData == null
      ? validateUserEmail?.data?.is_email_exist
        ? t("checkoutPage.validationMsg.EmailAlreadyEexistsPleaseLogin")
        : ""
      : null;

  /*** Confirm and Payment Booking Handler  ***/
  const handleConfirmPay = (e) => {
    e.preventDefault();
    const regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
    
    const expectedArrTimeRegex = /^(1[0-2]|0?[1-9]):[0-5][0-9]$/;
    const mobileNoError = MobileNumberValidator(
      userData.mobileNo,
      translatedMessageObj.MobileNumberValidatorMsgObj
    );
    const emailError = EmailValidator(
      userData.email,
      translatedMessageObj.EmailValidatorMsgObj
    );

    if (paymentModeValue === "cash") {
      if (userData.firstname == "") {
        firstnameRef.current.focus();
        setuserDataError({
          ...userDataError,
          firstnameErr: t(
            "checkoutPage.validationMsg.PleaseEnterYourFirstName"
          ),
        });
        return;
      } else if (emailError) {
        emailRef.current.focus();
        setuserDataError({
          ...userDataError,
          emailErr: emailError,
        });
        return;
      } else if (isEmailExist) {
        emailRef.current.focus();
        setuserDataError({
          ...userDataError,
          emailErr: isEmailExist,
        });
        return;
      } else if (userData.email != userData.confirmEmail) {
        confirmEmailRef.current.focus();
        setuserDataError({
          ...userDataError,
          confirmEmailErr: t(
            "checkoutPage.validationMsg.EmailandConfirmEmailNotSame"
          ),
        });
        return;
      } else if (countryCode == "") {
        // countryCodeRef.current.focus();
        setCountryCodeErr(
          t("checkoutPage.validationMsg.PleaseSelectYourcountrycode")
        );
        return;
      } else if (mobileNoError) {
        mobileNoRef.current.focus();
        setuserDataError({
          ...userDataError,
          mobileNoErr: mobileNoError,
        });
        return;
        
      } else if (cardData.termsandConditions == false) {
        setCardDataError({
          ...cardDataError,
          termsandConditionsErr: t(
            "checkoutPage.validationMsg.PleaseAcceptTermsandConditions"
          ),
        });
        return;
      } else if (cardData.payAtHotelConditions == false) {
        setCardDataError({
          ...cardDataError,
          payAtHotelConditionsErr: t(
            "checkoutPage.validationMsg.PleaseAcceptPayAtHotelConditions"
          ),
        });
        return;
      }

       
      else {
        const cardDetailsObj = {
          card_number: "871492145677",
          name_on_card: "BUBAI SAHA",
          exp_date: "04/25",
          security_code: "073",
          card_type: "debit",
          zip_code: "700144",
        };
        const postData = {
          search_type: hourlyCheckoutDetails?.data?.request_data?.search_type,
          hotel_id: hourlyCheckoutDetails?.data?.request_data?.hotel_id,
          room_type_id: hourlyCheckoutDetails?.data?.request_data?.room_type_id,
          // slot_id: hourlyCheckoutDetails?.data?.request_data?.slot_id,
          hour:
            hourlyCheckoutDetails?.data?.booking_details
              ?.total_length_of_stay_hours,
          check_in_date:
            hourlyCheckoutDetails?.data?.request_data?.check_in_date,
          
          check_in_time: selectedCheckInTime
            ? selectedCheckInTime
            : hourlyCheckoutDetails?.data?.booking_details?.check_in_time,
          check_out_time: selectedCheckInTime
            ? moment(
              moment(selectedCheckInTime, ["hh:mm"]).add(
                hourlyCheckoutDetails?.data?.booking_details
                  ?.total_length_of_stay_hours,
                "h"
              )
            ).format("HH:mm")
            : hourlyCheckoutDetails?.data?.booking_details?.check_out_time,
          adults: hourlyCheckoutDetails?.data?.request_data?.adults,
          children: hourlyCheckoutDetails?.data?.request_data?.children,
          no_of_rooms: hourlyCheckoutDetails?.data?.request_data?.no_of_rooms,
          user_details: {
            first_name: userData.firstname,
            last_name: userData.lastname,
            email: userData.email,
            country_code: countryCode,
            contact_no: userData.mobileNo,
            expected_time_of_arrival: userData.expecedTimeArrival,
          },
          
          no_of_points:
            walletBal != "" &&
              hourlyCheckoutDetails?.data?.user_details?.wallet_balance_points > 0
              ? walletBal
              : 0,
          
          promo_code:
            promoCode != "" && applyHotelOffersFailedData?.code != 3014
              ? promoCode
              : "",
          
          pay_type: paymentModeValue,
          card_details: cardDetailsObj,
          payment_summery_details: applyHotelOffers != null ? applyHotelOffers.data : hourlyCheckoutDetails?.data?.payment_summery_details,

          device_type: "web",
        };

        const data = {
          postData,
          languageToShow: languageToShow,
          token: userAuthData != null ? userAuthData.token : "",
        };
        if (selectedCheckInTime != "") {
          hotelBookingRequest(data);
          stateClearAfterTask();
          validateEmailStateClearAfterTask();
         
        } else {
          MySwal.fire({
            confirmButtonText: 'OK',
            confirmButtonColor: "#5287b3",
            text: t("checkoutPage.validationMsg.PleaseSelectCheckInTime")
          });
        }
      }
    } else {
      if (userData.firstname == "") {
        firstnameRef.current.focus();
        setuserDataError({
          ...userDataError,
          firstnameErr: t(
            "checkoutPage.validationMsg.PleaseEnterYourFirstName"
          ),
        });
        return;
      } else if (emailError) {
        emailRef.current.focus();
        setuserDataError({
          ...userDataError,
          emailErr: emailError,
        });
        return;
      } else if (isEmailExist) {
        emailRef.current.focus();
        setuserDataError({
          ...userDataError,
          emailErr: isEmailExist,
        });
        return;
      } else if (userData.email != userData.confirmEmail) {
        confirmEmailRef.current.focus();
        setuserDataError({
          ...userDataError,
          confirmEmailErr: t(
            "checkoutPage.validationMsg.EmailandConfirmEmailNotSame"
          ),
        });
        return;
      } else if (countryCode == "") {
        
        setCountryCodeErr(
          t("checkoutPage.validationMsg.PleaseSelectYourcountrycode")
        );
        return;
      } else if (mobileNoError) {
        mobileNoRef.current.focus();
        setuserDataError({
          ...userDataError,
          mobileNoErr: mobileNoError,
        });
        return;
      }

      
      else if (cardData.termsandConditions == false) {
        setCardDataError({
          ...cardDataError,
          termsandConditionsErr: t(
            "checkoutPage.validationMsg.PleaseAcceptTermsandConditions"
          ),
        });
        return;
      }
     
      else {
        const cardDetailsObj = {
          card_number: cardData.cardnumber.replaceAll(/\s/g, ""),
          name_on_card: cardData.nameoncard,
          exp_date: cardData.expirydate,
          security_code: cardData.securitycode,
          card_type: cardData.selectedCardType,
          zip_code: cardData.zipcode,
        };
        const postData = {
          search_type: hourlyCheckoutDetails?.data?.request_data?.search_type,
          hotel_id: hourlyCheckoutDetails?.data?.request_data?.hotel_id,
          room_type_id: hourlyCheckoutDetails?.data?.request_data?.room_type_id,
          // slot_id: hourlyCheckoutDetails?.data?.request_data?.slot_id,
          hour:
            hourlyCheckoutDetails?.data?.booking_details
              ?.total_length_of_stay_hours,
          check_in_date:
            hourlyCheckoutDetails?.data?.request_data?.check_in_date,
          // check_out_date:
          // hourlyCheckoutDetails?.data?.request_data?.check_in_date,
          check_in_time: selectedCheckInTime
            ? selectedCheckInTime
            : hourlyCheckoutDetails?.data?.booking_details?.check_in_time,
          check_out_time: selectedCheckInTime
            ? moment(
              moment(selectedCheckInTime, ["hh:mm"]).add(
                hourlyCheckoutDetails?.data?.booking_details
                  ?.total_length_of_stay_hours,
                "h"
              )
            ).format("HH:mm")
            : hourlyCheckoutDetails?.data?.booking_details?.check_out_time,
          adults: hourlyCheckoutDetails?.data?.request_data?.adults,
          children: hourlyCheckoutDetails?.data?.request_data?.children,
          no_of_rooms: hourlyCheckoutDetails?.data?.request_data?.no_of_rooms,
          user_details: {
            first_name: userData.firstname,
            last_name: userData.lastname,
            email: userData.email,
            country_code: countryCode,
            contact_no: userData.mobileNo,
            expected_time_of_arrival: userData.expecedTimeArrival,
          },
          // no_of_points: walletBal,
          no_of_points:
            walletBal != "" &&
              hourlyCheckoutDetails?.data?.user_details?.wallet_balance_points > 0
              ? walletBal
              : 0,
          // promo_code: promoCode,
          promo_code:
            promoCode != "" && applyHotelOffersFailedData?.code != 3014
              ? promoCode
              : "",
          // bid_now_amount: 0,
          pay_type: paymentModeValue,
          card_details: cardDetailsObj,
          payment_summery_details: {
            currency:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.currency
                : hourlyCheckoutDetails?.data?.payment_summery_details
                  ?.currency,

            net_rate:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.net_rate
                : hourlyCheckoutDetails?.data?.payment_summery_details
                  ?.net_rate,
            taxes:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.taxes
                : hourlyCheckoutDetails?.data?.payment_summery_details?.taxes,
            municipal_taxes:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.municipal_taxes
                : hourlyCheckoutDetails?.data?.payment_summery_details
                  ?.municipal_taxes,

          
            service_fee:
              applyHotelOffers != null &&
                applyHotelOffers?.data?.service_fee != null
                ? applyHotelOffers?.data?.service_fee
                : hourlyCheckoutDetails?.data?.payment_summery_details
                  ?.service_fee != null
                  ? hourlyCheckoutDetails?.data?.payment_summery_details
                    ?.service_fee
                  : 0,

            amount:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.amount
                : hourlyCheckoutDetails?.data?.payment_summery_details?.amount,

            amount_to_be_paid_now:
              applyHotelOffers != null
                ? applyHotelOffers?.data?.amount_to_be_paid_now
                : hourlyCheckoutDetails?.data?.payment_summery_details
                  ?.amount_to_be_paid_now,
          },
          device_type: "web",
        };

        const data = {
          postData,
          languageToShow: languageToShow,
          token: userAuthData != null ? userAuthData.token : "",
        };
        if (selectedCheckInTime != "") {
          hotelBookingRequest(data);
          stateClearAfterTask();
          validateEmailStateClearAfterTask();
          
        } else {
          MySwal.fire({
            confirmButtonText: 'OK',
            confirmButtonColor: "#5287b3",
            text: t("checkoutPage.validationMsg.PleaseSelectCheckInTime")
          });
        
        }
      }
    }
  };
  const refreshPage = () => {
    navigate(0);
  };

  React.useEffect(() => {
    if (hotelBooking != null) {
      if (hotelBooking.code == 4036 || hotelBooking.code == 4054) {
        const timeout = setTimeout(() => {
          navigate("/");
          saveGuestUserCheckoutDataRequest(null);
          stateClearAfterTask();
        }, 5001);
        return () => clearTimeout(timeout);
      } else {
      }
      // stateClearAfterTask();
    }
  }, [JSON.stringify(hotelBooking)]);

  React.useEffect(() => {
    if (hotelBooking != null) {
      if (hotelBooking.success == true) {
        // navigate("/");
        //   setShowConfirmDialog(true);
        window.open(`${hotelBooking.data.url}`, "_self");
        saveGuestUserCheckoutDataRequest(null);
        setPaymentButtonActive(!paymentButtonActive);
        // stateClearAfterTask();
      } else {
      }
      // stateClearAfterTask();
    }
  }, [JSON.stringify(hotelBooking)]);

  const viewBookingDetailsHandler = () => {
    setShowBookingConfirmModal(true);
    setShowConfirmDialog(false);
    // setShowBookingDetailsModal(true);
  };

  const showConfirmationPopup = () => {
    setShowBookingConfirmModal(true);
  };

  useEffect(() => {
    if (signUpmessage != null || currentUser != null) {
      handleCloseLoginPopup();
    }
  }, [signUpmessage, currentUser]);

  useEffect(() => {
    if (applyHotelOffersFailedData != null) {
      if (applyHotelOffersFailedData?.code == 3014) {
        setPromoCodeApplied(false);
        setPromoCode("");
      } else if (applyHotelOffersFailedData?.code == 4070) {
        setPromoCodeApplied(false);
        setPromoCode("");
      }
      else if (applyHotelOffersFailedData?.code == 4045) {
        setPointsApplied(false);
        setWalletBal("");
      } else if (applyHotelOffersFailedData?.code == 3020) {
        setPointsApplied(false);
        setWalletBal("");
      } else if (applyHotelOffersFailedData?.code == 4081) {
        setPromoCodeApplied(false);
        setPromoCode("");
      } else {
      }
    }
  }, [applyHotelOffersFailedData])

  useEffect(() => {
    if (applyHotelOffers != null) {
      setTotalAmount(applyHotelOffers?.data?.amount_to_be_paid_now);
    } else if (hourlyCheckoutDetails != null && totalAmount == "") {
      setTotalAmount(hourlyCheckoutDetails?.data.payment_summery_details.amount_to_be_paid_now);
    }
  }, [applyHotelOffers, hourlyCheckoutDetails]);
/////////////////////// Payment selected method chanage with payHotel not available 
 useEffect(() => {
 if(hourlyCheckoutDetails?.data?.is_offline_payment_available == false) {
 setPaymentModeValue("card"); 
 } else {
  setPaymentModeValue("cash"); 
 }
 },[hourlyCheckoutDetails?.data?.is_offline_payment_available])
 /////////////////////// Payment selected method chanage with payHotel not available  
  return (
    <>
      {/* //////////Checkout main content start/////////// */}
      

      <div className="container">
        <div className="WL_biduser_mainwrp">
          <div className="row">
            {/* ///Checkout left content start/// */}
            <div className="col-md-4">
              {/* <div className="WL_left-bibuser"> */}

              <div className="WL_left-bibuser custom-leftbid">
                <h3 className="WL_bookingheading text-capitalize">
                  {t("checkoutPage.YourHourlyBookingDetails")}
                </h3>
                <div className="WL_inner_leftwrp">
                  {/* ///checkout checkin wrp start/// */}
                  <div className="WL_checkwrp">
                    <div className="WL_checkwrp_left">
                      <p className="WL_check_txt">
                        {t("checkoutPage.CheckIn")}
                      </p>
                      <p className="WL_check_txt2">
                        {moment(
                          hourlyCheckoutDetails?.data?.booking_details
                            ?.check_in_date
                          // ,"DD/MM/YYYY"
                        ).format("ddd, ll")}
                      </p>
                      
                    </div>
                    <div className="WL_checkwrp_left">
                      <p className="WL_check_txt">
                        {t("checkoutPage.TotalStay")}
                      </p>
                      <p className="WL_totl_text">
                        <strong>
                          {
                            hourlyCheckoutDetails?.data?.booking_details
                              ?.total_length_of_stay_hours
                          }
                          {"  "}
                          {hourlyCheckoutDetails?.data?.booking_details
                            ?.total_length_of_stay_hours > 1
                            ? t("checkoutPage.Hours")
                            : t("checkoutPage.Hour")}
                        </strong>
                      </p>
                    </div>
                  </div>
                  {/* ///checkout checkin wrp end/// */}

                  {/* ///////Select Check In Time start/////// */}
                  <div className="WL_select_checkintime">
                    <p className="WL_select_checkintime_text">
                      {t("checkoutPage.SelectCheckInTime")}
                    </p>
                    <div className="WL_checkintime_selectbox">
                      <Form.Select
                        aria-label="CheckIn"
                        value={selectedCheckInTime}
                        onChange={(e) => {
                          setSelectedCheckInTime(e.target.value);
                        }}
                      >
                        
                        <option disabled value="">
                          
                          {t("checkoutPage.SelectTime")}
                        </option>
                        {hourlyCheckoutDetails?.data?.booking_details?.check_in_slots.map(
                          (CHISlot, index) => (
                            <option value={CHISlot} key={index}>
                              {moment(`${CHISlot}`, ["hh:mm"]).format(
                                "hh:mm A"
                              )}
                            </option>
                          )
                        )}
                      </Form.Select>
                    </div>
                  </div>
                  {/* ///////Select Check In Time end/////// */}

                  {/* ///////Select Check Out Time start/////// */}
                  <div className="WL_select_checkintime">
                    <p className="WL_select_checkintime_text">
                      {t("checkoutPage.CheckOutTime")}
                    </p>
                    <div className="WL_checkouttime_text">
                      {selectedCheckInTime
                        ? moment(
                          moment(selectedCheckInTime, ["hh:mm"]).add(
                            hourlyCheckoutDetails?.data?.booking_details
                              ?.total_length_of_stay_hours,
                            "h"
                          )
                        ).format("hh:mm A")
                        : moment(
                          `${hourlyCheckoutDetails?.data?.booking_details?.check_out_time}`,
                          ["hh:mm"]
                        ).format("hh:mm A")}
                    </div>
                  </div>
                  {/* ///////Select Check Out Time end/////// */}

                  {/* ///Total length wrp start/// */}
                  <div className="WL_totl_textwrp">
                    <p className="WL_totl_text">
                      <span>{t("checkoutPage.NoofRoom")}:</span>{" "}
                      <strong>
                        {hourlyCheckoutDetails?.data?.request_data.no_of_rooms}{" "}
                        {hourlyCheckoutDetails?.data?.request_data.no_of_rooms >
                          1
                          ? t("checkoutPage.Rooms")
                          : t("checkoutPage.Room")}
                      </strong>
                    </p>
                    <p className="WL_totl_text">
                      <span>{t("checkoutPage.RoomType")}:</span>{" "}
                      <strong>
                        {
                          hourlyCheckoutDetails?.data?.booking_details
                            ?.room_type_name
                        }
                      </strong>
                    </p>
                    <p className="WL_totl_text">
                      {/* Fits up to: */}
                      <span>{t("checkoutPage.NoofGuest")}:</span>{" "}
                      <strong>
                        {hourlyCheckoutDetails?.data?.request_data.adults}{" "}
                        {hourlyCheckoutDetails?.data?.request_data.adults > 1
                          ? t("checkoutPage.Guests")
                          : t("checkoutPage.Guest")}
                      </strong>
                    </p>
                    
                  </div>
                  {/* ///Total length wrp end/// */}

                  {/* ////////////////////Available balance wrp start/////////////// */}

                  {/* ///Bid amount wrp start/// */}
                  {userAuthData != null && (
                    <div className="WL_bidamnt_wrp mt-4">
                      <div className="WL_amnt_textheading">
                        {t("checkoutPage.AvailableWalletBalance")}
                      </div>
                      <div className="WL_pointbox">
                        {
                          hourlyCheckoutDetails?.data?.user_details
                            ?.wallet_balance_points
                        }{" "}
                        {t("checkoutPage.POINTS")}
                      </div>
                      <div className="d-flex justify-content-start w-100">
                        <p className="WL_totl_text_conversion">
                          (
                          <span>
                          {currencyToShow.current === "INR"
                            ? Number(
                              hourlyCheckoutDetails?.data
                                ?.wallet_points_conversion_rate
                                ?.wallet_point_value / 22
                            ).toFixed(2)
                            : hourlyCheckoutDetails?.data
                              ?.wallet_points_conversion_rate
                              ?.wallet_point_value}{" "}
                          {t("checkoutPage.Point")}
                          </span>{" "}
                          ={" "}
                          <span>
                            {Number(
                              hourlyCheckoutDetails?.data
                                ?.wallet_points_conversion_rate
                                ?.conversion_amount *
                              currencyToShow.convertedRates
                            ).toFixed(2)}{" "}
                            {currencyToShow.current}
                          </span>
                          )
                        </p>
                      </div>
                    </div>
                  )}

                  {/* ///Bid amount wrp end/// */}

                  {/* ///Card details wrp start/// */}
                  {userAuthData != null && (
                    <div className="WL_carddetailst_wrp customform applyform">
                      <Form onSubmit={applyWalletBal}>
                        <div className="WL_innercardholder">
                          <Form.Group controlId="formCheckoutCardDetails">
                            <Form.Control
                              type="text"
                              placeholder={t("checkoutPage.EnterwalletBalance")}
                              className="WL_frm_wcrd WL_frm_wcrd_points"
                              name="walletBal"
                              onChange={(e) => {
                                setWalletBal(e.target.value);
                                if (!validator.isNumeric(e.target.value)) {
                                  setWalletBalErr(
                                    t(
                                      "checkoutPage.validationMsg.PleaseEnteronlyNumber"
                                    )
                                  );
                                  return;
                                } else {
                                  setWalletBalErr("");
                                }
                              }}
                              value={walletBal}
                              disabled={
                                hourlyCheckoutDetails?.data?.user_details
                                  ?.wallet_balance_points > 0
                                  ? pointsApplied ? true : false
                                  : true
                              }
                            />
                            <Form.Text className="text-muted">
                              {walletBalErr}
                            </Form.Text>
                          </Form.Group>
                          <Button
                            variant=""
                            type="submit"
                            className="WL_card_submitbtn"
                            disabled={
                              hourlyCheckoutDetails?.data?.user_details
                                ?.wallet_balance_points > 0
                                ? false
                                : true
                            }
                          >
                            
                            {pointsApplied ? t("checkoutPage.remove") : t("checkoutPage.apply")}
                          </Button>


                        </div>
                      </Form>
                    </div>
                  )}
                  {/* ///card details wrp end/// */}
                  {/* ////////////////////Available balance wrp end/////////////// */}


                  {/* ///Total summary wrp start/// */}
                  <div className="WL_paymentwrp">
                    <Accordion id="payment_summery_show" name="payment_summery_show" ref={specificSectionRef}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h3>{t("checkoutPage.PaymentSummary.PaymentSummary")}</h3>
                      </AccordionSummary>
                      {/* Payment Summar Calculation Part */}
                      <AccordionDetails>
                        <div className="WL_payment_inerwrp ">
                          <div className="WL_payment_iner_left">
                            {t("checkoutPage.PaymentSummary.Netrate")}:
                          </div>
                          <div className="WL_payment_iner_right">
                            {


                            }

                            


                            {applyHotelOffers !== null &&
                              "data" in applyHotelOffers &&
                              "net_rate" in applyHotelOffers.data
                              ? Number(
                                applyHotelOffers.data.net_rate *
                                currencyToShow.convertedRates
                              ).toFixed(2)
                              : ""}
                            {applyHotelOffers === null &&
                              hourlyCheckoutDetails &&
                              "data" in hourlyCheckoutDetails &&
                              "payment_summery_details" in
                              hourlyCheckoutDetails.data &&
                              "net_rate" in
                              hourlyCheckoutDetails.data.payment_summery_details
                              ? Number(
                                hourlyCheckoutDetails.data.payment_summery_details
                                  .net_rate * currencyToShow.convertedRates
                              ).toFixed(2)
                              : ""}
                            {" " + currencyToShow.current}

                          </div>
                        </div>

                        <div className="WL_payment_inerwrp">
                          <div className="WL_payment_iner_left">
                            {t("checkoutPage.PaymentSummary.vat15per")}{applyHotelOffers != null ? applyHotelOffers.data.is_tax_percentage ? `(${applyHotelOffers.data.tax_amount}%)` : null : hourlyCheckoutDetails?.data.payment_summery_details.is_tax_percentage ? `(${hourlyCheckoutDetails?.data.payment_summery_details.tax_amount}%)` : null}:{" "}
                          </div>
                          <div className="WL_payment_iner_right">
                            

                            {applyHotelOffers !== null &&
                              "data" in applyHotelOffers &&
                              "taxes" in applyHotelOffers.data
                              ? Number(
                                applyHotelOffers.data.taxes *
                                currencyToShow.convertedRates
                              ).toFixed(2)
                              : ""}
                            {applyHotelOffers === null &&
                              hourlyCheckoutDetails &&
                              "data" in hourlyCheckoutDetails &&
                              "payment_summery_details" in
                              hourlyCheckoutDetails.data &&
                              "taxes" in
                              hourlyCheckoutDetails.data.payment_summery_details
                              ? Number(
                                hourlyCheckoutDetails.data.payment_summery_details
                                  .taxes * currencyToShow.convertedRates
                              ).toFixed(2)
                              : ""}
                            {" " + currencyToShow.current}
                            
                          </div>
                        </div>

                        {/******  municipal taxes**** */}

                        <div className="WL_payment_inerwrp_municiplity">
                          <div className="WL_payment_inerwrp ">
                            <div className="WL_payment_iner_left">
                              {t("checkoutPage.PaymentSummary.Municipality")}{applyHotelOffers != null ? applyHotelOffers.data.is_municipal_tax_percentage ? `(${applyHotelOffers.data.municipal_tax_amount}%)` : null : hourlyCheckoutDetails?.data.payment_summery_details.is_municipal_tax_percentage ? `(${hourlyCheckoutDetails?.data.payment_summery_details.municipal_tax_amount}%)` : null}:{" "}

                            </div>

                            <div className="WL_payment_iner_right">
                              {/* Municipality tax  Mar 30 */}

                              {applyHotelOffers !== null &&
                                "data" in applyHotelOffers &&
                                "municipal_taxes" in applyHotelOffers.data
                                ? Number(
                                  applyHotelOffers.data.municipal_taxes *
                                  currencyToShow.convertedRates
                                ).toFixed(2)
                                : ""}
                              {applyHotelOffers === null &&
                                hourlyCheckoutDetails &&
                                "data" in hourlyCheckoutDetails &&
                                "payment_summery_details" in
                                hourlyCheckoutDetails.data &&
                                "municipal_taxes" in
                                hourlyCheckoutDetails.data.payment_summery_details
                                ? Number(
                                  hourlyCheckoutDetails.data
                                    .payment_summery_details.municipal_taxes *
                                  currencyToShow.convertedRates
                                ).toFixed(2)
                                : ""}
                              {" " + currencyToShow.current}
                              {/* Municipality tax  Mar 30 */}
                            </div>
                          </div>
                          {/* Coupon code tax  Mar 30 */}


                          

                          
                          {applyHotelOffers != null
                            ? applyHotelOffers?.data?.coupon_amount_applied && (
                              <div className="WL_payment_inerwrp">
                                <div className="WL_payment_iner_left">
                                  <strong >
                                    {t(
                                      "checkoutPage.PaymentSummary.AppliedCouponAmount"
                                    )}:
                                  </strong>{" "}
                                </div>
                                <div className="WL_payment_iner_right">
                                  <strong >
                                    

                                    {applyHotelOffers !== null &&
                                      "data" in applyHotelOffers &&
                                      "coupon_amount_applied" in applyHotelOffers.data
                                      ? Number(
                                        applyHotelOffers.data
                                          .coupon_amount_applied *
                                        currencyToShow.convertedRates
                                      ).toFixed(2)
                                      : ""}

                                    {" " + currencyToShow.current}
                                  </strong>
                                </div>
                              </div>
                            )
                            : null}

                          
                        </div>
                        {/******  municipal taxes**** */}


                        <div className="WL_payment_inerwrp">
                          <div className="WL_payment_iner_left">
                            {t("checkoutPage.PaymentSummary.Servicefee")}:
                          </div>
                          <div className="WL_payment_iner_right">
                            
                              
                              {applyHotelOffers !== null &&
                              "data" in applyHotelOffers &&
                              "service_fee" in
                              applyHotelOffers.data
                              ? Number(
                                applyHotelOffers.data
                                  .service_fee *
                                currencyToShow.convertedRates
                              ).toFixed(2)
                              : ""}

                              {applyHotelOffers === null &&
                                hourlyCheckoutDetails &&
                                "data" in hourlyCheckoutDetails &&
                                "payment_summery_details" in
                                hourlyCheckoutDetails.data &&
                                "service_fee" in
                                hourlyCheckoutDetails.data.payment_summery_details
                                ? Number(
                                  hourlyCheckoutDetails.data
                                    .payment_summery_details.service_fee *
                                  currencyToShow.convertedRates
                                ).toFixed(2)
                                : ""}
                              {" " + currencyToShow.current}
                          </div>
                        </div>


                        {applyHotelOffers != null
                          ? applyHotelOffers?.data?.wallet_balance_applied && (
                            <div className="WL_payment_inerwrp">
                              <div className="WL_payment_iner_left">
                                <strong >
                                  {t(
                                    "checkoutPage.PaymentSummary.AppliedWalletBalance"
                                  )}
                                </strong>{" "}
                              </div>
                              <div className="WL_payment_iner_right">
                                <strong >
                                  

                                  {applyHotelOffers !== null &&
                                    "data" in applyHotelOffers &&
                                    "wallet_balance_applied" in
                                    applyHotelOffers.data
                                    ? Number(
                                      applyHotelOffers.data
                                        .wallet_balance_applied *
                                      currencyToShow.convertedRates
                                    ).toFixed(2)
                                    : ""}

                                  {" " + currencyToShow.current}
                                </strong>
                              </div>
                            </div>
                          )
                          : null}
                        {paymentModeValue == "cash" ?
                          <div className="WL_payment_inerwrp">
                            <div className="WL_payment_iner_left">
                              <strong>
                                {t("checkoutPage.PaymentSummary.AmountToBePaidNow")}
                              </strong>{" "}
                            </div>
                            <div className="WL_payment_iner_right">
                              <strong>
                               
                               
                                {
                                  applyHotelOffers !== null ?
                                  Number((Number(applyHotelOffers?.data.amount_to_be_paid_now) - Number(applyHotelOffers?.data.service_fee)) *
                                    currencyToShow.convertedRates).toFixed(2)
                                    : Number((Number(hourlyCheckoutDetails?.data.payment_summery_details.amount_to_be_paid_now) - Number(hourlyCheckoutDetails?.data?.payment_summery_details.service_fee)) *
                                    currencyToShow.convertedRates).toFixed(2)
                                }
                                {" " + currencyToShow.current}

                              

                                {/* Pay At hotem  Mar 30 */}
                              </strong>
                            </div>
                          </div>
                          : null}



                      </AccordionDetails>
                      {/* Wallet balance applied */}





                    </Accordion>
                    <div className="WL_payment_inerwrp total-payment">
                      <div className="WL_payment_iner_left">
                        <strong>
                          {t("checkoutPage.PaymentSummary.Amount")}
                        </strong>{" "}
                      </div>
                      <div className="WL_payment_iner_right">
                        <strong>

                          {paymentModeValue == "cash" ?
                            applyHotelOffers !== null ?
                              (Number(applyHotelOffers?.data.service_fee) *
                              Number(currencyToShow.convertedRates)).toFixed(2)
                              : (Number(hourlyCheckoutDetails?.data?.payment_summery_details.service_fee) * Number(currencyToShow.convertedRates)).toFixed(2)
                            :
                            applyHotelOffers !== null ?
                              (Number(applyHotelOffers?.data.amount_to_be_paid_now) * Number(currencyToShow.convertedRates)).toFixed(2)
                              : (Number(hourlyCheckoutDetails?.data.payment_summery_details.amount_to_be_paid_now) * Number(currencyToShow.convertedRates)).toFixed(2)

                          }
                          {" " + currencyToShow.current}

                        </strong>
                      </div>
                    </div>
                  </div>


                  
                </div>
              </div>
            </div>
            {/* ///Checkout left content end/// */}

            {/* ///Checkout right content start/// */}
            <div className="col-md-8">
              {/* ///review slider content start/// */}
              <CheckoutHotelDetails
                CheckoutHotelDetailsData={hourlyCheckoutDetails}
              />
              {/* ///review slider content end/// */}

              <Form onSubmit={handleConfirmPay}>
                {/* ///personal details start/// */}

                <CheckoutUserForm
                  userData={userData}
                  userDataError={userDataError}
                  handlePersonalDetailsChange={handlePersonalDetailsChange}
                  handleCountryCodeChange={handleCountryCodeChange}
                  countryCode={countryCode}
                  countryCodeErr={countryCodeErr}
                  firstnameRef={firstnameRef}
                  confirmEmailRef={confirmEmailRef}
                  emailRef={emailRef}
                  countryCodeRef={countryCodeRef}
                  mobileNoRef={mobileNoRef}
                  chandleChangeExpectedTime={handleChangeTimePicker}
                  expectedTimeArrRef={expectedTimeArrRef}
                  handleCheckExistingUser={handleCheckExistingUser}
                  handleShowLoginPopup={handleShowLoginPopup}
                  userAuthData={userAuthData}
                  userDataArrival={selectedCheckInTime}
                />

                {/* ///personal details  end/// */}

                {/* ///promo code wrapper start/// */}
                <div className="WL_persnal_wrap customform">
                  {/* ///promo code start/// */}
                  <h3 className="WL_amnt_textheading">
                    {t("checkoutPage.DoyouHaveaPromoCode")}
                  </h3>
                  <div className="WL_carddetailst_wrp customform">
                    {/* <Form onSubmit={applyPromoCode}> */}
                    <div className="WL_innercardholder">
                      <Form.Group controlId="formCheckoutCardPromo">
                        <Form.Control
                          type="text"
                          placeholder={t("checkoutPage.TEST1234")}
                          className="WL_frm_prmcode"
                          name="promoCode"
                          onChange={(e) => {
                            setPromoCode(e.target.value.toUpperCase());
                            if (e.target.value != "") {
                              setPromoCodeErr("");
                              return;
                            }
                          }}
                          disabled={promoCodeApplied}
                          value={promoCode}
                        />
                      </Form.Group>
                      <Button
                        variant=""
                        onClick={applyPromoCode}
                        className="WL_card_submitbtn"
                        disabled={promoCode == "" ? true : false}
                      >
                        
                        {promoCodeApplied ? t("checkoutPage.remove") : t("checkoutPage.apply")}
                      </Button>
                    </div>
                    
                    
                    
                  </div>
                  {/* ///promo code end/// */}

                  {/* ///amount paid display start/// */}

                  <div className="WL_paid_wrp">
                    <div className="WL_textleft">
                      {t("checkoutPage.PaymentSummary.TotalAmount")}
                    </div>

                    <div className="WL_textright">
                      
                      {applyHotelOffers !== null &&
                        "data" in applyHotelOffers &&
                        "amount_to_be_paid_now" in applyHotelOffers.data
                        ? Number(
                          applyHotelOffers.data.amount_to_be_paid_now *
                          currencyToShow.convertedRates
                        ).toFixed(2)
                        : ""}
                      {applyHotelOffers === null &&
                        hourlyCheckoutDetails &&
                        "data" in hourlyCheckoutDetails &&
                        "payment_summery_details" in hourlyCheckoutDetails.data &&
                        "amount_to_be_paid_now" in
                        hourlyCheckoutDetails.data.payment_summery_details
                        ? (Number(
                          totalAmount) * Number(currencyToShow.convertedRates)
                        ).toFixed(2)
                        : ""}
                      {" " + currencyToShow.current}
                    </div>
                  </div>
                  {/* ///amount paid display end/// */}

                  <h4 className="WL_personalheading">
                    {t("checkoutPage.PayNow")}
                  </h4>

                  <div className="WL_creditradio_mwrp">
                    {/* <Form> */}
                    <Form.Group
                      className="WL-custmpayradio"
                      controlId="formPayRadio"
                    >
                      {hourlyCheckoutDetails?.data?.hotel_details?.payment_options && hourlyCheckoutDetails?.data?.hotel_details?.payment_options.some(option => option.includes("Online")) && (
                        <Form.Check
                        type="radio"
                        checked={paymentModeValue === "card"}
                        name="paynowcreditcard"
                        id="paynowcreditcard"
                        label={t("checkoutPage.CreditCardorDebitCard")}
                        onChange={handlePaymentMode}
                        value={"card"}
                      />)}

                      {hourlyCheckoutDetails?.data?.hotel_details?.payment_options && hourlyCheckoutDetails?.data?.hotel_details?.payment_options.some(option => option.includes("Check-in")) &&
                     
                       hourlyCheckoutDetails?.data?.is_offline_payment_available == true && (

                        <Form.Check
                         type="radio"
                         name="paynowcreditcard"
                         id="paynowathotel"
                         label={t("checkoutPage.PayatHotel")}
                         checked={paymentModeValue === "cash"}
                         value={"cash"}
                         onChange={handlePaymentMode}
                        />
                        
                      )}
     
                    </Form.Group>
                    {/* </Form> */}
                  </div>
                  <div className="WL_cc_cardicon">
                    {/* <img src="./img/Paypal.svg" alt="" /> */}
                    <img src={UnionPay} height="54" width="54" style={{height:"auto"}} alt="" />
                    <img src="./img/Visa.svg" alt="" />
                    <img src="./img/mastercard.svg" alt="" />
                    <img src="./img/mada.svg" alt="" />
                    <img src={applepay} height="54" width="54" style={{height:"auto"}} alt="" />
                    {/* <img src="./img/Stc_pay.svg" alt="" /> */}
                  </div>

                  <div className="WL_creditfrmcont_mwrp">
                    {/* <Form> */}
                    {paymentModeValue === "cash" ? (
                      ""
                    ) : (<></>
                      
                    )}
                    <Form.Group
                      className="form-checkbox WL_checkbx_crdcard"
                      controlId="formCheckoutAgree"
                    >

                      <Form.Check
                        type="checkbox"
                        className="checkout_checkbox"
                        label={
                          <>
                            
                            {t("checkoutPage.IagreeToThe")}{" "}
                            {/* <Link to="#"> */}
                            <span style={{cursor:'pointer', fontWeight: '600', color: '#374a77'}} onClick={handleShowBookingConditionPopup} >
                            {t("checkoutPage.bookingcondition")}
                            </span>
                            {/* </Link> */}{" "}
                            <Link to="/terms&conditions">
                              {t("checkoutPage.TermsandConditions")}
                            </Link>
                             {/* {t("checkoutPage.and")}{" "} */}
                            <Link to="/privacy-policy">
                              {t("checkoutPage.PrivacyPolicies")}
                            </Link>{" "}
                            {t("checkoutPage.and")}{" "}
                            <Link to="/safety-security">
                              {t("checkoutPage.SafetyandSecurity")}
                            </Link>{" "}
                            {t("checkoutPage.of")}{" "}
                             {t("checkoutPage.Wfrleedotcom")}
                            {" "}
                            <div className="text-muted error-text">
                              {cardDataError.termsandConditionsErr}

                            </div>

                          </>
                        }
                        checked={cardData.termsandConditions}
                        onChange={handletermspolicy}
                      />
                      </Form.Group>
                      
                   

                    <Button variant="" disabled={paymentButtonActive} type="submit" className="formsubmit">
                      {/* Confirm and Pay */}
                      {hotelBookingLoading ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        t("checkoutPage.ConfirmandPay")
                      )}
                    </Button>
                    

                    {paymentModeValue === "cash" &&
                      <Form.Group
                        className="form-checkbox WL_checkbx_crdcard"
                        controlId="formPayAtHotelAgree"
                      >

                        <Form.Check
                          type="checkbox"
                          className="checkout_checkbox"
                          label={
                            <>
                              <div ref={contentRef} dangerouslySetInnerHTML={{
                                __html: (paymentModeValue === "cash"  && hourlyCheckoutDetails?.data?.hotel_details?.payment_options && hourlyCheckoutDetails?.data?.hotel_details?.payment_options.some(option => option.includes("Check-in")))? t("checkoutPage.checkoutPaymentDetails").replace("Payment Summary", "<b style='cursor: pointer;' class='payment_summary'>Payment Summary</b>").replace("مخلص الدفع", "<b style='cursor: pointer;'>مخلص الدفع</b>") : "",
                              }}></div>
                              <div className="text-muted error-text">
                                {cardDataError.payAtHotelConditionsErr}
                              </div>
                            </>
                          }
                          checked={cardData.payAtHotelConditions}
                          onChange={handlePayAtHotelConditions}
                        />
                      </Form.Group>
                    }
                  </div>
                </div>

                {/* ///promo code wrapper  end/// */}
              </Form>
            </div>
            {/* ///Checkout right content start/// */}
          </div>
        </div>

        <ConfirmDialog
          setShowConfirmDialog={setShowConfirmDialog}
          isOpen={showConfirmDialog}
          caption={
            hotelBooking != null
              ? t("checkoutPage.BookingConfirmedSuccessfully")
              : ""
          }
          description={
            <p>
              {t("checkoutPage.YourBookingNoIs")}{" "}
              <strong>
                {hotelBooking != null ? hotelBooking?.data?.booking_number : ""}
              </strong>{" "}
              {t("checkoutPage.forhotel")}{" "}
              <strong>
                {hotelBooking != null
                  ? hotelBooking?.data?.hotel_details?.name
                  : ""}
              </strong>
            </p>
          }
          confirmButtonLabel={t("checkoutPage.ViewBookingDetails")}
          continueButtonLabel={t("checkoutPage.GotoHome")}
          confirmButtonHandler={viewBookingDetailsHandler}
          bookingData={hotelBooking != null ? hotelBooking : null}
          isConfirmed={
            hotelBooking != null &&
              hotelBooking.success == true &&
              hotelBooking?.data != null
              ? true
              : false
          }
          cancelButtonHandler={() => {
            setShowConfirmDialog(false);
            navigate("/");
            stateClearAfterTask();
          }}
        />

        

        <LoginModal
          show={showLoginPopup}
          handleClose={handleCloseLoginPopup}
          search_type={hourlyCheckoutDetails?.data?.request_data?.search_type}
        >
          <LoginPage
            handleCloseLoginPopup={handleCloseLoginPopup}
            search_type={hourlyCheckoutDetails?.data?.request_data?.search_type}
          />
        </LoginModal>

        <BookingConditionModal 
          show={bookingConditionShow}
          handleClose={handleCloseBookingConditionPopup}
          bookingCondition={hourlyCheckoutDetails?.data?.cancellation_policy_data}
        />







       
        {/*<ViewPromoCodeList
          popUpState={videPromoList}
          ChildhandleClose={promoCodeListHide}
      />*/}
      </div>
      {/* //////////Checkout main content end/////////// */}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  hourlyCheckoutDetails: selectHourlyCheckoutDetails,
  hotelBooking: selectHotelBooking,
  languageToShow: selectlanguageToShow,
  userAuthData: selectUserLoginData,
  applyHotelOffers: selectApplyHotelOffers,
  saveGuestUserCheckoutData: selectSaveGuestUserCheckoutData,
  currencyToShow: selectcurrencyToShow,
  searchsavedData: selectFavouriteHotelSearchData,
  applyHotelOffersFailedData: selectApplyHotelOffersFailedData,
  validateUserEmail: selectValidateUserEmailData,
  hotelBookingLoading: selectHotelBookingLoading,
  applyHotelOffersLoading: selectApplyHotelOffersLoading,
  signUpmessage: selectSignUpMessage,
  currentUser: selectCurrentUser,
});
const mapDispatchToProps = (dispatch) => ({
  getHourlyCheckoutDetailsRequest: (data) =>
    dispatch(getHourlyCheckoutDetailsRequest(data)),
  hotelBookingRequest: (data) => dispatch(hotelBookingRequest(data)),
  applyHotelOffersRequest: (data) => dispatch(applyHotelOffersRequest(data)),
  stateClearAfterTask: () => dispatch(stateClearAfterTask()),
  saveGuestUserCheckoutDataRequest: (data) =>
    dispatch(saveGuestUserCheckoutDataRequest(data)),
  hotelWheatherApiRequest: (data) => dispatch(hotelWheatherApiRequest(data)),
  validateUserEmailRequest: (data) => dispatch(validateUserEmailRequest(data)),
  validateEmailStateClearAfterTask: () =>
    dispatch(validateEmailStateClearAfterTask()),
});
export default connect(mapStateToProps, mapDispatchToProps)(HourlyGuestUser);
